import React, { useEffect } from "react";
import { Table, Alert } from "react-bootstrap";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { useTheme } from "../contexts/ThemeContext";
import { useWebSocket } from "../contexts/WebSocketContext";
import { useLogger } from "../utils/logger";

const NewsRow = ({ newsItem, fontSize }) => (
  <tr>
    <td>
      <a
        href={newsItem.link}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: "none",
          color: "inherit",
        }}
      >
        {newsItem.image && (
          <img
            src={newsItem.image}
            alt={newsItem.title}
            style={{
              width: "auto",
              height: "40px",
            }}
            onError={(e) => e.target.style.display = 'none'}
          />
        )}
      </a>
    </td>
    <td className="text-start">
      <a
        href={newsItem.link}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: "none",
          fontSize: `${fontSize}px`,
          color: "inherit",
        }}
      >
        {newsItem.title}
      </a>
    </td>
  </tr>
);

const ComponentStates = {
  ENABLED: "enabled",
  DISABLED: "disabled",
  RESTRICTED: "restricted"
};

export const NFLNewsPage = withLDConsumer()(({ flags, fontSize = 16 }) => {
  const logger = useLogger();
  const { theme } = useTheme();
  const { newsData = [], sendMessage, connectionStatus, errors } = useWebSocket();

  // Log component initialization
  logger.info("NFLNewsPage initializing", {
    theme,
    fontSize,
    componentState: flags.nflNewsComponent,
    connectionStatus: connectionStatus.news
  });

  useEffect(() => {
    const fetchNews = async () => {
      if (
        connectionStatus.news === 'OPEN' && 
        flags.nflNewsComponent === ComponentStates.ENABLED
      ) {
        logger.debug("Fetching NFL news", {
          connectionStatus: connectionStatus.news,
          componentState: flags.nflNewsComponent
        });
        try {
          await sendMessage('news', {
            action: 'getNFLNews',
            type: 'fetchAll'
          });
        } catch (err) {
          logger.error("Failed to fetch NFL news", {
            error: err.message,
            stack: err.stack
          });
        }
      } else {
        logger.debug("Skipping news fetch", {
          connectionStatus: connectionStatus.news,
          componentState: flags.nflNewsComponent
        });
      }
    };

    fetchNews();
  }, [connectionStatus.news, flags.nflNewsComponent, sendMessage, logger]);

  const renderContent = () => {
    logger.trace("Rendering news content", {
      componentState: flags.nflNewsComponent,
      newsCount: newsData.length
    });

    switch (flags.nflNewsComponent) {
      case ComponentStates.DISABLED:
        return (
          <tr>
            <td>
              <h3>NFL News is not enabled</h3>
            </td>
          </tr>
        );

      case ComponentStates.RESTRICTED:
        return (
          <tr>
            <td>
              NFL News is only available to TUFF Premium Members. 
              Upgrade your TUFF subscription to Premium to access NFL News.
            </td>
          </tr>
        );

      case ComponentStates.ENABLED:
        return newsData.length > 0 ? (
          newsData.map((newsItem) => (
            <NewsRow 
              key={newsItem.id} 
              newsItem={newsItem} 
              fontSize={fontSize}
            />
          ))
        ) : (
          <tr>
            <td>Loading news...</td>
          </tr>
        );

      default:
        logger.warn("Unknown component state", {
          state: flags.nflNewsComponent
        });
        return null;
    }
  };

  const renderConnectionStatus = () => {
    logger.trace("Rendering connection status", {
      hasError: !!errors?.news,
      connectionStatus: connectionStatus.news
    });

    if (errors?.news) {
      return (
        <Alert variant="danger" className="mb-3">
          {errors.news}
        </Alert>
      );
    }

    if (connectionStatus.news === 'CONNECTING') {
      return (
        <Alert variant="warning" className="mb-3">
          Connecting to news feed...
        </Alert>
      );
    }

    if (connectionStatus.news === 'CLOSED') {
      return (
        <Alert variant="danger" className="mb-3">
          Connection lost. Attempting to reconnect...
        </Alert>
      );
    }

    return null;
  };

  logger.debug("Rendering NFLNewsPage", {
    hasError: !!errors?.news,
    connectionStatus: connectionStatus.news,
    newsCount: newsData.length,
    componentState: flags.nflNewsComponent
  });

  return (
    <div style={{ padding: "8px" }}>
      <h4>NFL News</h4>
      {renderConnectionStatus()}
      <div style={{ 
        border: `1px solid ${theme === 'dark' ? '#333' : '#dee2e6'}`,
        borderRadius: "4px",
        padding: "8px",
        backgroundColor: theme === 'dark' ? '#1a1a1a' : '#fff'
      }}>
        <Table hover size="sm" variant={theme}>
          <tbody>
            {renderContent()}
          </tbody>
        </Table>
      </div>
    </div>
  );
});

export default NFLNewsPage;
