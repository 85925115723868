import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from "react";
import { Card, Row, Col, ProgressBar } from "react-bootstrap";
import { useFontSize } from "../contexts/FontSizeContext";
import { useTheme } from "../contexts/ThemeContext";
import { getTeamLimits } from "../services/roster.service"; 
import { useAuth0 } from "@auth0/auth0-react";
import { useTradeData } from "../hooks/useTradeData";
import { useLogger } from "../utils/logger";

const SalaryCapDisplay = ({ teamRoster, salaryCap, isPlayoffTeam }) => {
  const logger = useLogger();
  logger.debug('Rendering SalaryCapDisplay', { teamRoster, salaryCap, isPlayoffTeam });
  const { fontSize } = useFontSize();
  const { theme } = useTheme();

 const totalSalary = teamRoster.reduce((sum, player) => sum + Number(player.salary || 0), 0);
 const salaryCapPercentage = Math.max(0, ((totalSalary / salaryCap) * 100).toFixed(1));
 const remaining = salaryCap - totalSalary;

 const getSalaryCapVariant = (percentage) => {
   if (percentage === 0) return "success";
   if (percentage >= 100) return "danger";
   if (percentage >= 90) return "warning";
   return "success";
 };

 return (
   <Card className="mb-3 h-100" bg={theme === "dark" ? "dark" : "light"} text={theme === "dark" ? "white" : "dark"}>
     <Card.Body>
       <Card.Title style={{ fontSize: `${fontSize * 1.2}px` }}>Salary Cap Information</Card.Title>
       <Row>
         <Col md={12}>
           <h6 style={{ fontSize: `${fontSize}px` }}>Salary Overview</h6>
           <div className="d-flex justify-content-between mb-2" style={{ fontSize: `${fontSize}px` }}>
             <span>Total Salary:</span>
             <span>${totalSalary.toFixed(2)}</span>
           </div>
           <div className="d-flex justify-content-between mb-2" style={{ fontSize: `${fontSize}px` }}>
             <span>Salary Cap:</span>
             <span>${salaryCap.toFixed(2)}</span>
           </div>
           <div className="d-flex justify-content-between mb-2" style={{ fontSize: `${fontSize}px` }}>
             <span>Remaining:</span>
             <span>${remaining.toFixed(2)}</span>
           </div>
           <ProgressBar
             now={Math.max(1, parseFloat(salaryCapPercentage))}
             variant={getSalaryCapVariant(parseFloat(salaryCapPercentage))}
             label={`${salaryCapPercentage}%`}
             style={{ fontSize: `${fontSize * 0.8}px`, height: "24px" }}
           />
         </Col>
       </Row>
     </Card.Body>
   </Card>
 );
};

const TradeInfoDisplay = ({ regularTradeCount, regularTradeLimit, irTradeCount, irTradeLimit, selectedWeek }) => {
  const logger = useLogger();
  const { fontSize } = useFontSize();
  const { theme } = useTheme();

  logger.debug('Rendering TradeInfoDisplay', {
    regularTradeCount,
    regularTradeLimit,
    irTradeCount,
    irTradeLimit,
    selectedWeek
  });
 
  // Calculate trade percentages with safety checks
  const regularTradePercentage = regularTradeCount !== undefined && regularTradeLimit !== undefined && regularTradeLimit !== 0 
    ? Math.round((regularTradeCount / regularTradeLimit) * 100) 
    : 0;

  const irTradePercentage = irTradeCount !== undefined && irTradeLimit !== undefined && irTradeLimit !== 0
    ? Math.round((irTradeCount / irTradeLimit) * 100)
    : 0;

  logger.debug('Calculated trade percentages', {
    regularTradePercentage,
    irTradePercentage
  });
 
  const getTradeVariant = (percentage) => {
    if (percentage === 0) return "success";
    if (percentage >= 100) return "danger";
    if (percentage >= 75) return "warning";
    return "success";
  };

  // Calculate remaining trades with safety checks
  const regularTradeRemaining = regularTradeLimit !== undefined && regularTradeCount !== undefined 
    ? regularTradeLimit - regularTradeCount 
    : 0;

  const irTradeRemaining = irTradeLimit !== undefined && irTradeCount !== undefined
    ? irTradeLimit - irTradeCount
    : 0;

  logger.debug('Calculated remaining trades', {
    regularTradeRemaining,
    irTradeRemaining
  });
 
  return (
    <Card className="mb-3 h-100" bg={theme === "dark" ? "dark" : "light"} text={theme === "dark" ? "white" : "dark"}>
      <Card.Body>
        <Card.Title style={{ fontSize: `${fontSize * 1.2}px` }}>Trade Information</Card.Title>
        <Row>
          <Col md={6}>
            <h6 style={{ fontSize: `${fontSize}px` }}>Regular Trades</h6>
            <div className="d-flex justify-content-between mb-2" style={{ fontSize: `${fontSize}px` }}>
              <span>Limit:</span>
              <span>{regularTradeLimit || 0}</span>
            </div>
            <div className="d-flex justify-content-between mb-2" style={{ fontSize: `${fontSize}px` }}>
              <span>Made:</span>
              <span>{regularTradeCount || 0}</span>
            </div>
            <div className="d-flex justify-content-between mb-2" style={{ fontSize: `${fontSize}px` }}>
              <span>Remaining:</span>
              <span>{regularTradeRemaining}</span>
            </div>
            <ProgressBar
              now={regularTradePercentage}
              variant={getTradeVariant(regularTradePercentage)}
              label={`${regularTradePercentage}%`}
              style={{ fontSize: `${fontSize * 0.8}px`, height: "24px" }}
            />
          </Col>
          <Col md={6}>
            <h6 style={{ fontSize: `${fontSize}px` }}>IR Trades</h6>
            <div className="d-flex justify-content-between mb-2" style={{ fontSize: `${fontSize}px` }}>
              <span>Limit:</span>
              <span>{irTradeLimit || 0}</span>
            </div>
            <div className="d-flex justify-content-between mb-2" style={{ fontSize: `${fontSize}px` }}>
              <span>Made:</span>
              <span>{irTradeCount || 0}</span>
            </div>
            <div className="d-flex justify-content-between mb-2" style={{ fontSize: `${fontSize}px` }}>
              <span>Remaining:</span>
              <span>{irTradeRemaining}</span>
            </div>
            <ProgressBar
              now={irTradePercentage}
              variant={getTradeVariant(irTradePercentage)}
              label={`${irTradePercentage}%`}
              style={{ fontSize: `${fontSize * 0.8}px`, height: "24px" }}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const DataUnavailableCard = ({ title, message }) => {
 const logger = useLogger();
 const { fontSize } = useFontSize();
 const { theme } = useTheme();

 logger.debug('Rendering DataUnavailableCard', { title, message });

 return (
   <Card className="mb-3 h-100" bg={theme === "dark" ? "dark" : "light"} text={theme === "dark" ? "white" : "dark"}>
     <Card.Body>
       <Card.Title style={{ fontSize: `${fontSize * 1.2}px` }}>{title}</Card.Title>
       <div className="text-center py-4" style={{ fontSize: `${fontSize}px` }}>
         {message}
       </div>
     </Card.Body>
   </Card>
 );
};

export const CapAndTradeInfo = forwardRef(({
  teamRoster, 
  selectedTeam, 
  currentWeek, 
  selectedWeek,
  onTradeInfoUpdate
}, ref) => {
  const logger = useLogger();
  const { getAccessTokenSilently } = useAuth0();

  logger.debug("Initializing CapAndTradeInfo", {
    selectedTeam,
    currentWeek,
    selectedWeek,
    rosterSize: teamRoster?.length
  });
  const {
    regularTradeCount,
    regularTradeLimit,
    irTradeCount,
    irTradeLimit,
    tradeError,
    fetchTradeCounts
  } = useTradeData(selectedTeam, selectedWeek);

  // Expose fetchTradeCounts to parent via ref
  useImperativeHandle(ref, () => ({
    fetchTradeCounts
  }));

 const [salaryCapError, setSalaryCapError] = useState(null);
 const [teamLimits, setTeamLimits] = useState(null);
 const [isLoading, setIsLoading] = useState(true);
 const [previousWeek, setPreviousWeek] = useState(null);

 const fetchTeamLimits = useCallback(async () => {
  if (!selectedTeam) return;

  try {
    const accessToken = await getAccessTokenSilently();
    // Pass selectedWeek as the third parameter
    const response = await getTeamLimits(accessToken, selectedTeam, selectedWeek);

    logger.debug("Team limits response received", { response });

    if (response.error) {
      logger.error("Server error fetching team limits", { error: response.error });
      setSalaryCapError(response.error);
      setTeamLimits(null);
      return;
    }

    if (response.data && response.data.salaryCap) {
      logger.debug("Setting team limits", { data: response.data });
      setTeamLimits(response.data);
      setSalaryCapError(null);
    } else {
      logger.error("Invalid team limits response format");
      setSalaryCapError("Salary cap data is missing from server response");
      setTeamLimits(null);
    }
  } catch (error) {
    logger.error("Failed to fetch team limits", { error });
    setSalaryCapError("Failed to load salary cap information");
    setTeamLimits(null);
  }
}, [selectedTeam, selectedWeek, getAccessTokenSilently, logger]);

 useEffect(() => {
   if (selectedWeek !== previousWeek) {
     fetchTradeCounts();
   }
 }, [selectedWeek, previousWeek, fetchTradeCounts]);

 useEffect(() => {
  logger.debug('Trade limits and counts updated', {
    regularTradeCount,
    regularTradeLimit,
    irTradeCount,
    irTradeLimit
  });

  const regularTradeRemaining = regularTradeLimit !== undefined && regularTradeCount !== undefined 
    ? regularTradeLimit - regularTradeCount 
    : 0;

  const irTradeRemaining = irTradeLimit !== undefined && irTradeCount !== undefined
    ? irTradeLimit - irTradeCount
    : 0;

  // Calculate salary cap info
  const totalSalary = teamRoster.reduce((sum, player) => sum + Number(player.salary || 0), 0);
  const salaryCap = teamLimits?.salaryCap || 0;
  const availableCap = salaryCap - totalSalary;

  logger.debug('Calculated cap info', {
    totalSalary,
    salaryCap,
    availableCap
  });

  // Update parent with both trade and cap info
  onTradeInfoUpdate?.({
    regular: regularTradeRemaining,
    ir: irTradeRemaining,
    currentCap: totalSalary,
    availableCap: availableCap,
    salaryCap: salaryCap,
    isPlayoffTeam: teamLimits?.isPlayoffTeam
  });
}, [
  regularTradeCount,
  regularTradeLimit,
  irTradeCount,
  irTradeLimit,
  teamRoster,
  teamLimits,
  onTradeInfoUpdate,
  logger
]);

 useEffect(() => {
   const loadData = async () => {
     if (!selectedTeam || !currentWeek) return;

     setIsLoading(true);
     setSalaryCapError(null);
     setPreviousWeek(currentWeek);

     try {
       await Promise.all([
         fetchTeamLimits(),
         fetchTradeCounts()
       ]);
     } catch (error) {
       logger.error("Failed to load cap and trade data", { error });
     } finally {
       setIsLoading(false);
     }
   };

   loadData();
 }, [selectedTeam, currentWeek, selectedWeek, fetchTeamLimits, fetchTradeCounts, logger]);

 if (isLoading) {
  return (
    <Card>
      <Card.Body>Loading...</Card.Body>
    </Card>
  );
}

logger.debug('Preparing to render SalaryCapDisplay', {
  teamLimits,
  salaryCap: teamLimits?.salaryCap,
  isPlayoffTeam: teamLimits?.isPlayoffTeam
});

return (
  <Row>
    <Col>
      <Row>
        <Col md={6}>
          {teamLimits && teamLimits.salaryCap ? (
            <SalaryCapDisplay
              teamRoster={teamRoster}
              salaryCap={teamLimits.salaryCap}
              isPlayoffTeam={teamLimits.isPlayoffTeam}
            />
          ) : (
             <DataUnavailableCard 
               title="Salary Cap Information" 
               message={salaryCapError || "Salary cap information is currently unavailable"}
             />
           )}
         </Col>
         <Col md={6}>
           {tradeError ? (
             <DataUnavailableCard 
               title="Trade Information" 
               message={tradeError}
             />
           ) : (
             <TradeInfoDisplay
               regularTradeCount={regularTradeCount}
               regularTradeLimit={regularTradeLimit}
               irTradeCount={irTradeCount}
               irTradeLimit={irTradeLimit}
               selectedWeek={selectedWeek}
             />
           )}
         </Col>
       </Row>
     </Col>
   </Row>
 );
});

export default CapAndTradeInfo;
