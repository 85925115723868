import React, { useEffect } from "react";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { useWebSocket } from "../contexts/WebSocketContext";

const ConnectionStates = {
  CONNECTING: "CONNECTING",
  OPEN: "OPEN",
  CLOSED: "CLOSED"
};

export const NFLNewsFooter = withLDConsumer()(({ flags }) => {
  const { latestNewsData, errors, connectionStatus, sendMessage } = useWebSocket();

  useEffect(() => {
    const fetchLatestNews = async () => {
      if (connectionStatus.latestNews === ConnectionStates.OPEN) {
        try {
          await sendMessage('latestNews', {
            action: 'getNFLNews',
            type: 'fetchAll'
          });
        } catch (err) {
          console.error('Failed to fetch latest news:', err);
        }
      }
    };

    fetchLatestNews();
  }, [connectionStatus.latestNews, sendMessage]);

  if (connectionStatus.latestNews === ConnectionStates.CONNECTING) {
    return (
      <div style={{ 
        display: "flex",
        alignItems: "center",
        height: "32px", // Match toggle height
        color: "#666"
      }}>
        Connecting to news feed...
      </div>
    );
  }

  if (errors.latestNews) {
    return (
      <div style={{ 
        display: "flex",
        alignItems: "center",
        height: "32px", // Match toggle height
        color: "#ff0000"
      }}>
        {errors.latestNews}
      </div>
    );
  }

  const displayNews = Array.isArray(latestNewsData)
    ? latestNewsData.slice(0, 5)
    : [];

  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      height: "32px", // Match toggle height
      width: "100%",
      overflow: "hidden"
    }}>
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        overflowX: "auto",
        gap: "50px", // Use gap for consistent spacing
        paddingLeft: "50px" // Initial offset
      }}>
        {displayNews.length > 0 ? (
          displayNews.map((newsItem, index) => (
            <div
              key={newsItem.id || index}
              style={{
                display: "inline-flex",
                flexShrink: 0
              }}
            >
              <a
                href={newsItem.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {newsItem?.image && (
                  <img
                    src={newsItem.image}
                    alt={newsItem.title || 'News image'}
                    style={{
                      height: "32px", // Match toggle height
                      width: "auto",
                      marginRight: "10px",
                      objectFit: "contain",
                      flexShrink: 0
                    }}
                    onError={(e) => e.target.style.display = 'none'}
                  />
                )}
                <span style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap"
                }}>
                  {newsItem?.title || 'No title available'}
                </span>
              </a>
            </div>
          ))
        ) : (
          <div style={{ 
            display: "flex",
            alignItems: "center",
            height: "32px", // Match toggle height
            color: "#666"
          }}>
            No recent news available
          </div>
        )}
      </div>
    </div>
  );
});

export default NFLNewsFooter;