// External library imports
import React, { useState, useCallback, useMemo } from "react";
import {
  Container,
  Alert,
  Spinner,
  ButtonGroup,
  ToggleButton,
  Form,
} from "react-bootstrap";
import { CaretUpFill, CaretDownFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

// Internal imports
import { useTheme } from "../contexts/ThemeContext";
import { useFontSize } from "../contexts/FontSizeContext";
import { StyledTable } from "../components/StyledTable";
import { useWebSocket } from "../contexts/WebSocketContext";
import { useLogger } from "../utils/logger";

// Constants
const REGULAR_SEASON_WEEKS = 14; // Number of weeks in regular season

/**
 * Playoff Structure and Finalization:
 * - Regular season ends at Week 14, marked as "Regular Season (Final)"
 * - Playoffs run from Week 15 to Week 17
 * - After Week 17:
 *   - If no tie for first place: Marked as "Playoffs (Final)"
 *   - If tie exists: Week 18 used as tiebreaker
 * 
 * Week 18 Tiebreaker Rules:
 * - Only used if teams are tied for first after Week 17
 * - Only tied teams participate
 * - Other teams' standings finalized based on Weeks 15-17
 * - Points calculation includes Week 18 only for tied teams
 */

/**
 * TUFFStandingsPage Component
 * 
 * Displays fantasy football standings with weekly breakdowns and statistics:
 * - Regular season (Weeks 1-14): Shows weekly points, achievements, and earnings
 * - Playoffs (Weeks 15-17): Shows playoff points and potential earnings
 * - Week 18: Only used as tiebreaker if teams are tied for first after Week 17
 * 
 * Features:
 * - Real-time updates via WebSocket connection
 * - Responsive design with mobile/desktop layouts
 * - Theme and font size customization
 * - Toggle between recent/all weeks view
 * - Color-coded weekly achievements
 * 
 * Data Display:
 * - Weekly points and rankings
 * - Season totals and points differentials
 * - Achievement tracking (GOTW, top placements)
 * - Regular season and playoff earnings
 * 
 * Implementation:
 * - Uses React hooks for state and context
 * - Optimized with memoization and callbacks
 * - Handles loading and error states
 * - Maintains responsive table layout
 */
export const TUFFStandingsPage = () => {
  // Context hooks
  const logger = useLogger();
  const { theme } = useTheme();
  const { fontSize } = useFontSize();
  const { standingsData, errors, connectionStatus, isLoading } = useWebSocket();

  // Log component initialization
  logger.info("TUFFStandingsPage initializing", {
    theme,
    fontSize,
    hasStandingsData: !!standingsData?.regularSeason?.length,
    currentWeek: standingsData?.currentNFLWeek
  });

  // Local state for view preferences
  const [weekViewMode, setWeekViewMode] = useState("recent"); // Controls display of "recent" vs "all" weeks
  const [showRegularSeasonWeeks, setShowRegularSeasonWeeks] = useState(false); // Toggle for showing regular season weeks during playoffs

  // Memoized style values and constants
  const headerFontSize = useMemo(
    () => `${parseFloat(fontSize) * 1.5}px`,
    [fontSize]
  );

  /**
   * Legend configuration for weekly achievements
   * Maps achievement types to their corresponding Bootstrap color classes
   */
  const legendItems = useMemo(
    () => [
      { rank: "GOTW", bootstrapClass: "primary" }, // Game of the Week
      { rank: "2nd Place", bootstrapClass: "warning" },
      { rank: "3rd Place", bootstrapClass: "danger" },
      { rank: "4th Place", bootstrapClass: "info" },
      { rank: "Top 10", bootstrapClass: "success" },
    ],
    []
  );

  /**
   * Helper Functions
   */
  /**
   * Determines the Bootstrap color class based on weekly ranking
   * @param {number} rank - The weekly rank position
   * @returns {string} Bootstrap color class name
   */
  const getColorForRank = useCallback((rank) => {
    if (rank === 1) return "primary"; // GOTW
    if (rank === 2) return "warning"; // 2nd Place
    if (rank === 3) return "danger";  // 3rd Place
    if (rank === 4) return "info";    // 4th Place
    if (rank >= 5 && rank <= 10) return "success"; // Top 10
    return "";
  }, []);

  /**
   * Formats a number as a dollar amount
   * @param {number} amount - The amount to format
   * @returns {string} Formatted dollar amount
   */
  const formatDollarAmount = useCallback((amount) => {
    return amount % 1 === 0 ? `$${amount}` : `$${amount.toFixed(2)}`;
  }, []);

  /**
   * Calculates playoff points for a team (Weeks 15-17)
   * @param {Object} team - Team data object containing weeklyPoints
   * @returns {number} Total playoff points from weeks 15-17
   */
  const calculatePlayoffPoints = useCallback((team) => {
    if (!team?.weeklyPoints) {
      logger.debug("No weekly points data for team", { team: team?.teamName });
      return 0;
    }
    
    const points = Object.entries(team.weeklyPoints)
      .filter(([week]) => {
        const weekNum = parseInt(week.split(" ")[1]);
        return weekNum >= 15 && weekNum <= 17;
      })
      .reduce((sum, [, points]) => sum + (points || 0), 0);

    logger.trace("Calculated playoff points", {
      team: team.teamName,
      points,
      weeklyPoints: team.weeklyPoints
    });
    
    return points;
  }, [logger]);

  /**
   * Checks if Week 18 data exists, indicating Week 17 is complete
   * @returns {boolean} Whether Week 17 is complete
   */
  const isWeek17Complete = useCallback(() => {
    const isComplete = standingsData.playoffs?.some(team => 
      team.weeklyPoints?.["Week 18"] !== undefined
    );
    
    logger.debug("Checking Week 17 completion", {
      isComplete,
      playoffTeamsCount: standingsData.playoffs?.length,
      hasWeek18Data: standingsData.playoffs?.some(team => team.weeklyPoints?.["Week 18"] !== undefined)
    });
    
    return isComplete;
  }, [standingsData.playoffs, logger]);

  /**
   * Checks if Week 18 should be shown and used for playoff calculations
   * @returns {boolean} Whether Week 18 should be shown
   * 
   * This determines both:
   * 1. If Week 18 column should be displayed
   * 2. If playoffs should be marked as "(Final)" after Week 17
   * 
   * Returns true only if:
   * - Week 18 data exists (meaning Week 17 is complete) AND
   * - At least 2 playoff teams exist AND
   * - Those teams are tied in playoff points (Weeks 15-17)
   */
  const shouldShowWeek18 = useCallback(() => {
    const week17Done = isWeek17Complete();
    const hasEnoughTeams = standingsData.playoffs?.length >= 2;
    const hasTie = hasEnoughTeams && 
                   calculatePlayoffPoints(standingsData.playoffs[0]) === calculatePlayoffPoints(standingsData.playoffs[1]);
    
    logger.debug("Checking Week 18 display criteria", {
      week17Complete: week17Done,
      playoffTeamsCount: standingsData.playoffs?.length,
      hasTie,
      team1Points: hasEnoughTeams ? calculatePlayoffPoints(standingsData.playoffs[0]) : null,
      team2Points: hasEnoughTeams ? calculatePlayoffPoints(standingsData.playoffs[1]) : null
    });
    
    return week17Done && hasEnoughTeams && hasTie;
  }, [standingsData.playoffs, calculatePlayoffPoints, isWeek17Complete, logger]);

  /**
   * Calculates which weeks should be displayed based on current settings
   * @param {boolean} isPlayoff - Whether we're in playoff mode
   * @param {number} currentWeek - Current NFL week number
   * @returns {string[]} Array of week labels to display
   */
  const calculateDisplayWeeks = useCallback(
    (isPlayoff, currentWeek) => {
      logger.debug("Calculating display weeks", {
        isPlayoff,
        currentWeek,
        weekViewMode,
        showRegularSeasonWeeks
      });
      if (isPlayoff) {
        const startWeek = REGULAR_SEASON_WEEKS + 1; // Week 15
        const maxPlayoffWeek = 17; // Regular playoff weeks end at 17
        const endWeek = shouldShowWeek18() ? 18 : Math.min(currentWeek, maxPlayoffWeek);
        
        return Array.from(
          { length: Math.max(0, endWeek - startWeek + 1) },
          (_, i) => `Week ${startWeek + i}`
        );
      }

      const showBonusAndPlayoffColumns = currentWeek > REGULAR_SEASON_WEEKS;

      if (showBonusAndPlayoffColumns && showRegularSeasonWeeks) {
        return Array.from(
          { length: REGULAR_SEASON_WEEKS },
          (_, i) => `Week ${i + 1}`
        );
      }

      if (showBonusAndPlayoffColumns && !showRegularSeasonWeeks) {
        return [];
      }

      const effectiveWeek = Math.min(currentWeek, REGULAR_SEASON_WEEKS);
      const allWeeks = Array.from(
        { length: effectiveWeek },
        (_, i) => `Week ${i + 1}`
      );

      return weekViewMode === "all" ? allWeeks : allWeeks.slice(-4);
    },
    [weekViewMode, showRegularSeasonWeeks, shouldShowWeek18, logger]
  );

  /**
   * Renders a standings table with weekly breakdowns and statistics
   * @param {Array} data - Array of team/manager data
   * @param {string} title - Table title
   * @param {boolean} isPlayoff - Whether this is a playoff standings table
   * @returns {JSX.Element} Rendered table component
   */
  const renderTable = useCallback(
    (data, title, isPlayoff = false) => {
      logger.trace("Rendering standings table", {
        title,
        isPlayoff,
        teamsCount: data?.length,
        currentWeek: standingsData.currentNFLWeek
      });
      const displayWeeks = calculateDisplayWeeks(
        isPlayoff,
        standingsData.currentNFLWeek
      );
      const showBonusAndPlayoffColumns =
        standingsData.currentNFLWeek > REGULAR_SEASON_WEEKS;

      const showWeekColumns =
        isPlayoff ||
        !showBonusAndPlayoffColumns ||
        (showBonusAndPlayoffColumns && showRegularSeasonWeeks);

      return (
        <div className="tuff-standings-wrapper">
          <h4
            className="dashboard-table-title"
            style={{ fontSize: `calc(${fontSize}px * 1.5)` }}
          >
            {title}
          </h4>

          {!isPlayoff && showBonusAndPlayoffColumns && (
            <div className="mb-3 text-center d-flex justify-content-center align-items-center">
              <Form.Check
                type="switch"
                id="week-view-switch"
                label="Show All Weeks"
                checked={showRegularSeasonWeeks}
                onChange={(e) => setShowRegularSeasonWeeks(e.target.checked)}
                style={{ fontSize: fontSize }}
              />
            </div>
          )}

          {!isPlayoff && !showBonusAndPlayoffColumns && (
            <div className="mb-3 text-center d-none d-md-flex justify-content-center position-relative">
              <ButtonGroup size="sm">
                <ToggleButton
                  id="recent-weeks"
                  type="radio"
                  variant={theme === "dark" ? "outline-light" : "outline-dark"}
                  name="week-view"
                  value="recent"
                  checked={weekViewMode === "recent"}
                  onChange={(e) => setWeekViewMode(e.currentTarget.value)}
                  style={{ fontSize: fontSize }}
                >
                  Recent Weeks
                </ToggleButton>
                <ToggleButton
                  id="all-weeks"
                  type="radio"
                  variant={theme === "dark" ? "outline-light" : "outline-dark"}
                  name="week-view"
                  value="all"
                  checked={weekViewMode === "all"}
                  onChange={(e) => setWeekViewMode(e.currentTarget.value)}
                  style={{ fontSize: fontSize }}
                >
                  All Weeks
                </ToggleButton>
              </ButtonGroup>
              {weekViewMode === "recent" && (
                <div
                  className="position-absolute start-50 translate-middle-x"
                  style={{ top: "100%", fontSize: fontSize }}
                >
                  <small className="text-muted">
                    Showing last 3 weeks + current week
                  </small>
                </div>
              )}
            </div>
          )}

          <div className="table-responsive">
            <StyledTable
              bordered
              size="sm"
              variant={theme}
              className="align-middle"
            >
              <thead>
                <tr>
                  <th className="dashboard-rank-column">Rank</th>
                  {(isPlayoff || !showBonusAndPlayoffColumns) && (
                    <th className="dashboard-rank-change-column"></th>
                  )}
                  <th className="dashboard-team-column">Team</th>
                  {showWeekColumns && (
                    <>
                      <th className="mobile-only dashboard-week-column">
                        {displayWeeks[displayWeeks.length - 1]}
                      </th>
                      {displayWeeks.map((week) => (
                        <th
                          key={week}
                          className="desktop-only dashboard-week-column"
                        >
                          {week}
                        </th>
                      ))}
                    </>
                  )}
                  <th className="dashboard-points-column">
                    {isPlayoff ? "Playoff Total" : "Season Total"}
                  </th>
                  {!isPlayoff ? (
                    <>
                      <th className="dashboard-points-column">Points Back</th>
                      <th className="dashboard-points-column">
                        Points from 6th
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        Top 10
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        GOTW
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        2nd Place
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        3rd Place
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        4th Place
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        Regular Season $
                      </th>
                      {showBonusAndPlayoffColumns && (
                        <>
                          <th className="desktop-only dashboard-points-column">
                            Reg Season Bonus $
                          </th>
                          <th className="desktop-only dashboard-points-column">
                            Total Regular Season $
                          </th>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <th className="dashboard-points-column">Points Back</th>
                      <th className="desktop-only dashboard-points-column">
                        Playoff $
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.map((manager) => {
                  const rankings = manager?.rankings || {};
                  const achievements = manager?.achievements || {};
                  const earnings = manager?.earnings || {};
                  const weeklyPoints = manager?.weeklyPoints || {};
                  const totalRegularSeasonEarnings =
                    (earnings.regularSeasonEarnings || 0) +
                    (earnings.bonusPayout || 0);

                  const totalPlayoffPoints = isPlayoff
                    ? Object.entries(weeklyPoints)
                        .filter(([week]) => {
                          const weekNum = parseInt(week.split(" ")[1]);
                          return (weekNum >= 15 && weekNum <= 17) || (weekNum === 18 && shouldShowWeek18());
                        })
                        .reduce((sum, [, points]) => sum + (points || 0), 0)
                    : 0;

                  let pointsBack = 0;
                  if (isPlayoff) {
                    // Calculate max points using the same week filtering logic
                    const maxPoints = Math.max(
                      ...data.map((m) => {
                        const weeklyPoints = m?.weeklyPoints || {};
                        return Object.entries(weeklyPoints)
                          .filter(([week]) => {
                            const weekNum = parseInt(week.split(" ")[1]);
                            return (weekNum >= 15 && weekNum <= 17) || (weekNum === 18 && shouldShowWeek18());
                          })
                          .reduce((sum, [, points]) => sum + (points || 0), 0);
                      })
                    );
                    pointsBack = maxPoints - totalPlayoffPoints;
                  }

                  return (
                    <tr key={manager.managerEmail}>
                      <td className="dashboard-rank-column">
                        {rankings?.currentRank || "-"}
                      </td>
                      {(isPlayoff || !showBonusAndPlayoffColumns) && (
                        <td className="dashboard-rank-change-column">
                          {standingsData.currentNFLWeek > 1 &&
                            rankings.previousRank !== rankings.currentRank &&
                            (rankings.previousRank > rankings.currentRank ? (
                              <CaretUpFill className="text-success" size={12} />
                            ) : (
                              <CaretDownFill
                                className="text-danger"
                                size={12}
                              />
                            ))}
                        </td>
                      )}
                      <td>
                        <Link
                          to={`/fantasypoints/${encodeURIComponent(
                            manager.teamName || manager.managerEmail
                          )}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {manager.teamName || manager.managerEmail}
                        </Link>
                      </td>
                      {showWeekColumns && (
                        <>
                          <td className="mobile-only">
                            {(() => {
                              const weeklyPointsValue =
                                weeklyPoints[
                                  displayWeeks[displayWeeks.length - 1]
                                ] || 0;
                              const rank =
                                standingsData.weeklyRankings[
                                  displayWeeks[displayWeeks.length - 1]
                                ]?.[manager.managerEmail];
                              const colorClass =
                                !isPlayoff && weeklyPointsValue > 0
                                  ? getColorForRank(rank)
                                  : "";
                              return (
                                <div
                                  className={`mobile-week-points ${
                                    colorClass ? `custom-bg ${colorClass}` : ""
                                  }`}
                                >
                                  {weeklyPointsValue}
                                </div>
                              );
                            })()}
                          </td>
                          {displayWeeks.map((week) => {
                            const weeklyPointsValue = weeklyPoints[week] || 0;
                            const rank =
                              standingsData.weeklyRankings[week]?.[
                                manager.managerEmail
                              ];
                            const colorClass =
                              !isPlayoff && weeklyPointsValue > 0
                                ? getColorForRank(rank)
                                : "";
                            return (
                              <td
                                key={week}
                                className={`desktop-only dashboard-week-column ${
                                  colorClass ? `custom-bg ${colorClass}` : ""
                                }`}
                              >
                                {weeklyPointsValue}
                              </td>
                            );
                          })}
                        </>
                      )}
                      <td>
                        {isPlayoff
                          ? totalPlayoffPoints
                          : manager.totalPoints || 0}
                      </td>
                      {!isPlayoff ? (
                        <>
                          <td>{rankings.pointsFromFirst || 0}</td>
                          <td>{rankings.pointsFromSixth || 0}</td>
                          <td className="desktop-only">
                            {achievements.top10Count || 0}
                          </td>
                          <td className="desktop-only">
                            {achievements.gotwCount || 0}
                          </td>
                          <td className="desktop-only">
                            {achievements.secondPlaceCount || 0}
                          </td>
                          <td className="desktop-only">
                            {achievements.thirdPlaceCount || 0}
                          </td>
                          <td className="desktop-only">
                            {achievements.fourthPlaceCount || 0}
                          </td>
                          <td className="desktop-only">
                            {formatDollarAmount(
                              earnings.regularSeasonEarnings || 0
                            )}
                          </td>
                          {showBonusAndPlayoffColumns && (
                            <>
                              <td className="desktop-only">
                                {formatDollarAmount(earnings.bonusPayout || 0)}
                              </td>
                              <td className="desktop-only">
                                {formatDollarAmount(totalRegularSeasonEarnings)}
                              </td>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <td>{pointsBack}</td>
                          <td className="desktop-only">
                            {formatDollarAmount(earnings.playoffEarnings || 0)}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </StyledTable>
          </div>
        </div>
      );
    },
    [
      calculateDisplayWeeks,
      fontSize,
      theme,
      weekViewMode,
      showRegularSeasonWeeks,
      standingsData,
      getColorForRank,
      formatDollarAmount,
      shouldShowWeek18,
      logger
    ]
  );

  /**
   * Renders the color-coded legend for weekly achievements
   * @returns {JSX.Element} Legend component with color indicators
   */
  const renderLegend = useCallback(
    () => (
      <div className="mt-3 text-center">
        <h6>Legend:</h6>
        <div className="d-flex flex-wrap gap-2 justify-content-center align-items-center">
          {legendItems.map((item) => (
            <div key={item.rank} className="d-flex align-items-center mx-2">
              <div
                className={`bg-${item.bootstrapClass}`}
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "5px",
                  border: "1px solid black",
                }}
              />
              <span>{item.rank}</span>
            </div>
          ))}
        </div>
      </div>
    ),
    [legendItems]
  );

  /**
   * Main render - Displays standings tables based on current state
   * Handles loading states, errors, and conditional rendering of playoff/regular season tables
   */
return (
  <Container fluid>
    {logger.debug("Rendering TUFFStandingsPage", {
      hasError: !!errors.standings,
      isLoading,
      connectionStatus: connectionStatus.standings,
      hasStandingsData: !!standingsData?.regularSeason?.length,
      currentWeek: standingsData?.currentNFLWeek,
      playoffTeamsCount: standingsData?.playoffs?.length
    })}
    <div className="py-3">
      <h4 style={{ fontSize: headerFontSize }}>2024 TUFF Standings</h4>
    </div>

    {/* Show error message if there's an error */}
    {errors.standings ? (
      <Alert variant="danger" className="my-3">
        {errors.standings}
      </Alert>
    ) : /* Show loading spinner until we have confirmed data */ 
    isLoading || 
    connectionStatus.standings === "CONNECTING" || 
    !standingsData?.regularSeason?.length ? (
      <div className="text-center d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "200px" }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading standings data...</span>
        </Spinner>
      </div>
    ) : (
      /* Show standings content only when we have confirmed data */
      <div className="standings-content">
        {(() => {
          const showBonusAndPlayoffColumns =
            standingsData.currentNFLWeek > REGULAR_SEASON_WEEKS;
            
          logger.debug("Determining standings display mode", {
            showBonusAndPlayoffColumns,
            currentWeek: standingsData.currentNFLWeek,
            hasPlayoffTeams: standingsData.playoffs?.length > 0,
            isWeek17Complete: isWeek17Complete(),
            shouldShowWeek18: shouldShowWeek18()
          });

          return (
            <>
              {standingsData.currentNFLWeek > REGULAR_SEASON_WEEKS &&
                standingsData.playoffs.length > 0 && (
                  <section className="mb-4">
                    {renderTable(
                      standingsData.playoffs,
                      `Playoffs${isWeek17Complete() && !shouldShowWeek18() ? " (Final)" : ""}`,
                      true
                    )}
                  </section>
              )}

              <section className="mb-4">
                {renderTable(
                  standingsData.regularSeason,
                  "Regular Season" +
                    (showBonusAndPlayoffColumns ? " (Final)" : ""),
                  false
                )}
              </section>

              {(!showBonusAndPlayoffColumns || showRegularSeasonWeeks) && (
                <section className="d-flex justify-content-center">
                  {renderLegend()}
                </section>
              )}
            </>
          );
        })()}
      </div>
    )}
  </Container>
);
};

// Final export
export default React.memo(TUFFStandingsPage);
