import { callExternalApi } from './external-api.service';
import { compareWeeks } from "../components/WeekUtils";
import { logger } from "../utils/logger";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getTradeCount = async (accessToken, email, startWeek, endWeek, tradeType) => {
  logger.debug("Fetching trade count", { 
    email, 
    startWeek, 
    endWeek, 
    tradeType 
  });

  // Check if the current week is before Week 2
  if (compareWeeks(endWeek, "Week 2") < 0) {
    logger.debug("Trades not started yet - before Week 2", { endWeek });
    return { data: "not-started", error: null };
  }

  // Validate week format
  const weekRegex = /^Week \d+$/;
  if (!weekRegex.test(startWeek) || !weekRegex.test(endWeek)) {
    logger.error("Invalid week format provided", { startWeek, endWeek });
    return { 
      data: null, 
      error: "Weeks must be in format 'Week X' where X is a number" 
    };
  }

  // Validate week range
  const startWeekNum = parseInt(startWeek.split(" ")[1]);
  const endWeekNum = parseInt(endWeek.split(" ")[1]);
  if (startWeekNum > endWeekNum) {
    logger.error("Invalid week range", { startWeekNum, endWeekNum });
    return {
      data: null,
      error: "Start week cannot be after end week"
    };
  }

  const config = {
    url: `${apiServerUrl}/api/trades/count`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      email,
      startWeek,
      endWeek,
      tradeType: tradeType.toLowerCase()
    },
  };

  logger.debug("Preparing API request", { config });

  try {
    const response = await callExternalApi({ config });
    logger.debug("Received API response", { response });

    // Check for error in response
    if (response.error) {
      logger.error("Failed to fetch trade count", { error: response.error });
      return { data: null, error: response.error };
    }

    // Check for valid response data structure
    if (response.data && response.data.data) {
      const tradeData = response.data.data;
      logger.debug("Trade count retrieved", { 
        count: tradeData.count,
        periodComplete: tradeData.periodComplete
      });
      
      // Return the count and additional metadata
      return { 
        data: tradeData.count, 
        periodComplete: tradeData.periodComplete,
        error: null 
      };
    }

    logger.error("Unexpected response format from API", { response });
    return { 
      data: null, 
      error: "Unexpected response format" 
    };

  } catch (error) {
    logger.error("Unexpected error in getTradeCount", { error });
    return { 
      data: null, 
      error: error.message || "An unexpected error occurred" 
    };
  }
};
