// Section 1: Imports and Initial Setup
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import {
  Container,
  Alert,
  Spinner,
  Form,
  Table,
  Pagination,
  Row,
  Col,
} from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useWebSocket } from "../contexts/WebSocketContext";
import { useTheme } from "../contexts/ThemeContext";
import { useFontSize } from "../contexts/FontSizeContext";
import { useLogger } from "../utils/logger";
import { getCurrentWeek } from "../services/schedule.service";

export const PlayerFantasyPointsPage = () => {
  // Section 2: State Declarations
  // Context hooks
  const logger = useLogger();
  const { theme } = useTheme();
  const { fontSize } = useFontSize();
  const { getAccessTokenSilently } = useAuth0();
  const { fantasyPointsData, connectionStatus, sendMessage } = useWebSocket();

  // Log component initialization
  logger.info("PlayerFantasyPointsPage initializing", {
    theme,
    fontSize,
    connectionStatus
  });

  // Header font size calculation
  const headerFontSize = `${parseFloat(fontSize) * 1.5}px`;

  // Week selection states
  const [selectedWeek, setSelectedWeek] = useState("Total");
  const [availableWeeks, setAvailableWeeks] = useState(["Total"]);
  const [currentWeek, setCurrentWeek] = useState("");

  // Data states
  const [playersData, setPlayersData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    return window.innerWidth < 768 ? 10 : 20;
  });

  // Filter states
  const [playerNameFilter, setPlayerNameFilter] = useState("");
  const [positionFilter, setPositionFilter] = useState("All");
  const [teamFilter, setTeamFilter] = useState("All Teams");
  const [tuffTeamFilter, setTuffTeamFilter] = useState("All Teams");

  // Sort states
  const [sortColumn, setSortColumn] = useState("fantasyPoints");
  const [sortDirection, setSortDirection] = useState("desc");

  // Memoized derived values
  const uniqueTeams = useMemo(() => {
    logger.trace("Calculating unique teams", {
      selectedWeek,
      playersCount: playersData.length
    });
    if (selectedWeek === "Total") {
      const teams = [
        ...new Set(playersData.map((player) => player.teamAbv).filter(Boolean)),
      ];
      return ["All Teams", ...teams.sort()];
    } else {
      const teams = [
        ...new Set(playersData.map((player) => player.team).filter(Boolean)),
      ];
      return ["All Teams", ...teams.sort()];
    }
  }, [selectedWeek, playersData, logger]);

  const getUniqueTuffTeams = useMemo(() => {
    logger.trace("Calculating unique TUFF teams");
    const teams = new Set();
    playersData.forEach((player) => {
      if (player.tuffTeams) {
        player.tuffTeams.forEach((team) => teams.add(team));
      }
    });
    return ["All Teams", "Free Agents", ...Array.from(teams).sort()];
  }, [playersData, logger]);

  // Section 3: Data Functions
  // Sort handler
  const handleSort = useCallback((column) => {
    logger.debug("Handling sort", {
      column,
      previousColumn: sortColumn,
      newDirection: sortDirection === "asc" ? "desc" : "asc"
    });
    setSortColumn(column);
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
  }, [sortColumn, sortDirection, logger]);

  // Week change handler
  const handleWeekChange = useCallback(
    (newWeek) => {
      logger.debug("Week selection changed", {
        previousWeek: selectedWeek,
        newWeek
      });
      setSelectedWeek(newWeek);
      setLoading(true);
      setPlayersData([]); // Clear current data while loading
      setFilteredData([]); // Clear filtered data while loading

      if (newWeek === "Total") {
        // Check if we already have total points loaded
        if (
          fantasyPointsData.allPlayersFullyLoaded &&
          fantasyPointsData.allPlayers?.length > 0
        ) {
          logger.debug("Using cached total points data");
          setPlayersData(fantasyPointsData.allPlayers);
          setFilteredData(fantasyPointsData.allPlayers);
          setLoading(false);
        } else {
          logger.debug("Requesting total points data");
          sendMessage("fantasyPoints", { action: "getAllPoints" });
        }
      } else {
        // Check if we already have this week's data loaded
        const weekData = fantasyPointsData.weeklyStats?.[newWeek] || [];
        const isWeekLoaded = fantasyPointsData.weeklyStatsLoaded?.[newWeek];

        if (weekData.length > 0 && isWeekLoaded) {
          logger.debug("Using cached weekly data", { week: newWeek });
          setPlayersData(weekData);
          setFilteredData(weekData);
          setLoading(false);
        } else {
          logger.debug("Requesting weekly data", { week: newWeek });
          sendMessage("fantasyPoints", {
            action: "getWeeklyPoints",
            week: newWeek,
            includeWeek: true,
          });
        }
      }
    },
    [sendMessage, fantasyPointsData, selectedWeek, logger]
  );

  // Section 4: Effect Hooks
  const initialLoadRef = useRef({
    loadStarted: false,
    loadComplete: false,
  });

  // Initial data loading effect with single getCurrentWeek call
  useEffect(() => {
    if (
      connectionStatus.fantasyPoints !== "OPEN" ||
      initialLoadRef.current.loadStarted
    )
      return;

    initialLoadRef.current.loadStarted = true;
    setLoading(true);

    const loadInitialData = async () => {
      logger.debug("Starting initial data load");
      try {
        const accessToken = await getAccessTokenSilently();
        const currentWeekResponse = await getCurrentWeek(accessToken);
        setCurrentWeek(currentWeekResponse.data);

        const weeks = Array.from({ length: 17 }, (_, i) => `Week ${i + 1}`);
        setAvailableWeeks(["Total", ...weeks]);

        if (!playersData.length) {
          logger.debug("Requesting initial fantasy points data");
          sendMessage("fantasyPoints", { action: "getAllPoints" });
        }

        initialLoadRef.current.loadComplete = true;
        logger.debug("Initial data load complete");
      } catch (err) {
        logger.error("Failed to load initial data", {
          error: err.message,
          stack: err.stack
        });
        setError(err.message);
        setLoading(false);
      }
    };

    loadInitialData();
  }, [
    connectionStatus.fantasyPoints,
    getAccessTokenSilently,
    sendMessage,
    playersData.length,
    logger
  ]);

  // WebSocket data update effect
  useEffect(() => {
    if (!fantasyPointsData) return;

    logger.debug("Processing WebSocket data update", {
      selectedWeek,
      hasAllPlayers: !!fantasyPointsData.allPlayers?.length,
      allPlayersFullyLoaded: fantasyPointsData.allPlayersFullyLoaded,
      hasWeeklyData: !!fantasyPointsData.weeklyStats?.[selectedWeek]?.length,
      weekLoaded: fantasyPointsData.weeklyStatsLoaded?.[selectedWeek]
    });

    if (selectedWeek === "Total") {
      if (
        fantasyPointsData.allPlayersFullyLoaded &&
        fantasyPointsData.allPlayers?.length > 0
      ) {
        setPlayersData(fantasyPointsData.allPlayers);
        setFilteredData(fantasyPointsData.allPlayers);
        setLoading(false);
      }
    } else {
      const weekData = fantasyPointsData.weeklyStats?.[selectedWeek] || [];
      const isWeekDataLoaded =
        fantasyPointsData.weeklyStatsLoaded?.[selectedWeek];

      if (weekData.length > 0 && isWeekDataLoaded) {
        setPlayersData(weekData);
        setFilteredData(weekData);
        setLoading(false);
      }
    }
  }, [fantasyPointsData, selectedWeek, logger]);

  // Data filtering effect
  useEffect(() => {
    if (!playersData.length) return;

    logger.debug("Applying filters", {
      playerNameFilter,
      positionFilter,
      teamFilter,
      tuffTeamFilter,
      sortColumn,
      sortDirection
    });

    const filtered = playersData.filter((player) => {
      const positionMatch =
        positionFilter === "All" ||
        (positionFilter === "DEF"
          ? selectedWeek === "Total"
            ? player.position === "Team Defense"
            : player.position === "DEF"
          : player.position === positionFilter);

      const teamMatch =
        teamFilter === "All Teams" ||
        (selectedWeek === "Total"
          ? player.teamAbv === teamFilter
          : player.team === teamFilter);

      const tuffTeamMatch =
        tuffTeamFilter === "All Teams"
          ? true
          : tuffTeamFilter === "Free Agents"
          ? !player.tuffTeams || player.tuffTeams.length === 0
          : player.tuffTeams && player.tuffTeams.includes(tuffTeamFilter);

      const nameMatch =
        !playerNameFilter ||
        (player.playerName &&
          player.playerName
            .toLowerCase()
            .includes(playerNameFilter.toLowerCase()));

      return positionMatch && teamMatch && tuffTeamMatch && nameMatch;
    });

    logger.trace("Filter results", {
      totalPlayers: playersData.length,
      filteredCount: filtered.length
    });

    const sorted = [...filtered].sort((a, b) => {
      let aValue, bValue;

      switch (sortColumn) {
        case "playerName":
          aValue = a.playerName || "";
          bValue = b.playerName || "";
          break;
        case "position":
          aValue = a.position || "";
          bValue = b.position || "";
          break;
        case "team":
          aValue = selectedWeek === "Total" ? a.teamAbv || "" : a.team || "";
          bValue = selectedWeek === "Total" ? b.teamAbv || "" : b.team || "";
          break;
        case "tuffTeam":
          aValue =
            a.tuffTeams && a.tuffTeams.length > 0
              ? a.tuffTeams[0]
              : "Free Agent";
          bValue =
            b.tuffTeams && b.tuffTeams.length > 0
              ? b.tuffTeams[0]
              : "Free Agent";
          break;
        default:
          aValue = a.totalFantasyPoints || 0;
          bValue = b.totalFantasyPoints || 0;
      }

      const compareResult =
        typeof aValue === "string"
          ? aValue.localeCompare(bValue)
          : aValue - bValue;

      return sortDirection === "asc" ? compareResult : -compareResult;
    });

    setFilteredData(sorted);
    setCurrentPage(1);
  }, [
    playersData,
    playerNameFilter,
    teamFilter,
    tuffTeamFilter,
    positionFilter,
    sortColumn,
    sortDirection,
    selectedWeek,
    logger
  ]);

  // Section 5: Render Methods
  // Pagination calculations
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const currentData =
    itemsPerPage === "all"
      ? filteredData
      : filteredData.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        );

  // Loading check
  if (
    loading ||
    !playersData.length ||
    (selectedWeek === "Total" && !fantasyPointsData?.allPlayersFullyLoaded)
  ) {
    logger.debug("Showing loading state", {
      loading,
      hasPlayersData: !!playersData.length,
      allPlayersFullyLoaded: fantasyPointsData?.allPlayersFullyLoaded
    });
    return (
      <Container fluid>
        <div className="text-center mt-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading player data...</span>
          </Spinner>
          <div className="mt-2">Loading player data...</div>
        </div>
      </Container>
    );
  }

  // Render pagination items
  const renderPagination = () => {
    if (itemsPerPage === "all") return null;

    let items = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    // First and Prev buttons
    items.push(
      <Pagination.First
        key="first"
        onClick={() => setCurrentPage(1)}
        disabled={currentPage === 1}
      />
    );
    items.push(
      <Pagination.Prev
        key="prev"
        onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
        disabled={currentPage === 1}
      />
    );

    // Start ellipsis
    if (startPage > 1) {
      items.push(<Pagination.Ellipsis key="ellipsis-start" />);
    }

    // Page numbers
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => setCurrentPage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    // End ellipsis
    if (endPage < totalPages) {
      items.push(<Pagination.Ellipsis key="ellipsis-end" />);
    }

    // Next and Last buttons
    items.push(
      <Pagination.Next
        key="next"
        onClick={() => setCurrentPage((prev) => Math.min(totalPages, prev + 1))}
        disabled={currentPage === totalPages}
      />
    );
    items.push(
      <Pagination.Last
        key="last"
        onClick={() => setCurrentPage(totalPages)}
        disabled={currentPage === totalPages}
      />
    );

    return (
      <Pagination className="flex-wrap justify-content-center">
        {items}
      </Pagination>
    );
  };

  logger.debug("Rendering main content", {
    totalPlayers: playersData.length,
    filteredCount: filteredData.length,
    currentPage,
    itemsPerPage,
    totalPages
  });

  // Main render
  return (
    <Container fluid>
      <div className="player-points-wrapper">
        <h4
          style={{ fontSize: headerFontSize }}
          className="text-center mt-3 mb-4"
        >
          Player Fantasy Points
        </h4>

        {error && <Alert variant="danger">{error}</Alert>}

        <div className="player-points-content">
          {/* Filter Controls */}
          <Row className="justify-content-center">
            <Col md={11}>
              <Form>
                <Row className="justify-content-center align-items-end">
                  {/* Player Name Filter */}
                  <Col md={2}>
                    <Form.Group className="mb-3">
                      <Form.Label>Player Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search by player name"
                        value={playerNameFilter}
                        onChange={(e) => setPlayerNameFilter(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  {/* Position Filter */}
                  <Col md={2}>
                    <Form.Group className="mb-3">
                      <Form.Label>Position</Form.Label>
                      <Form.Select
                        value={positionFilter}
                        onChange={(e) => setPositionFilter(e.target.value)}
                      >
                        <option value="All">All Positions</option>
                        <option value="QB">Quarterback</option>
                        <option value="RB">Running Back</option>
                        <option value="WR">Wide Receiver</option>
                        <option value="TE">Tight End</option>
                        <option value="DEF">DEF</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  {/* NFL Team Filter */}
                  <Col md={2}>
                    <Form.Group className="mb-3">
                      <Form.Label>NFL Team</Form.Label>
                      <Form.Select
                        value={teamFilter}
                        onChange={(e) => setTeamFilter(e.target.value)}
                      >
                        {uniqueTeams.map((team) => (
                          <option key={team} value={team}>
                            {team}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  {/* TUFF Team Filter */}
                  <Col md={2}>
                    <Form.Group className="mb-3">
                      <Form.Label>TUFF Team</Form.Label>
                      <Form.Select
                        value={tuffTeamFilter}
                        onChange={(e) => setTuffTeamFilter(e.target.value)}
                      >
                        {getUniqueTuffTeams.map((team) => (
                          <option key={team} value={team}>
                            {team}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  {/* Week Selection */}
                  <Col md={2}>
                    <Form.Group className="mb-3">
                      <Form.Label>Select Week</Form.Label>
                      <Form.Select
                        value={selectedWeek}
                        onChange={(e) => handleWeekChange(e.target.value)}
                      >
                        {availableWeeks.map((week) => (
                          <option key={week} value={week}>
                            {week === currentWeek ? `${week} (Current)` : week}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  {/* Items Per Page Selection */}
                  <Col md={2}>
                    <Form.Group className="mb-3">
                      <Form.Label>Players per Page</Form.Label>
                      <Form.Select
                        value={itemsPerPage}
                        onChange={(e) => {
                          const value = e.target.value;
                          setItemsPerPage(
                            value === "all" ? "all" : Number(value)
                          );
                          setCurrentPage(1);
                        }}
                      >
                        <option value={10}>10 per page</option>
                        <option value={20}>20 per page</option>
                        <option value={50}>50 per page</option>
                        <option value={100}>100 per page</option>
                        <option value="all">Show All</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>

          {/* Table */}
          <Row className="justify-content-center">
            <Col md={11}>
              <div className="player-points-table-container">
                <Table
                  bordered
                  striped
                  hover
                  size="sm"
                  variant={theme}
                  className="align-middle"
                >
                  <thead>
                    <tr>
                      <th
                        className="player-name-column"
                        onClick={() => handleSort("playerName")}
                      >
                        Player Name{" "}
                        {sortColumn === "playerName" && (
                          <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
                        )}
                      </th>
                      <th
                        className="player-position-column"
                        onClick={() => handleSort("position")}
                      >
                        Position{" "}
                        {sortColumn === "position" && (
                          <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
                        )}
                      </th>
                      <th
                        className="player-team-column"
                        onClick={() => handleSort("team")}
                      >
                        Team{" "}
                        {sortColumn === "team" && (
                          <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
                        )}
                      </th>
                      <th
                        className="player-points-column"
                        onClick={() => handleSort("totalFantasyPoints")}
                      >
                        Fantasy Points{" "}
                        {selectedWeek !== "Total"
                          ? `(${selectedWeek})`
                          : "(Total)"}{" "}
                        {sortColumn === "totalFantasyPoints" && (
                          <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
                        )}
                      </th>
                      <th
                        className="player-tuff-team-column"
                        onClick={() => handleSort("tuffTeam")}
                      >
                        TUFF Team(s){" "}
                        {sortColumn === "tuffTeam" && (
                          <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((player, index) => (
                      <tr key={index}>
                        <td className="player-name-column">
                          {player.playerName}
                        </td>
                        <td className="player-position-column">
                          {selectedWeek === "Total" &&
                          player.position === "Team Defense"
                            ? "DEF"
                            : player.position}
                        </td>
                        <td className="player-team-column">
                          {selectedWeek === "Total"
                            ? player.teamAbv
                            : player.team}
                        </td>
                        <td className="player-points-column">
                          {player.totalFantasyPoints}
                        </td>
                        <td
                          className="player-tuff-team-column"
                          title={
                            player.tuffTeams && player.tuffTeams.join(", ")
                          }
                        >
                          {player.tuffTeamAbbreviations &&
                          player.tuffTeamAbbreviations.length > 0
                            ? player.tuffTeamAbbreviations.join(", ")
                            : "Free Agent"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>

          {/* Pagination */}
          <Row className="justify-content-center">
            <Col md={11}>
              <div className="d-flex justify-content-center">
                {renderPagination()}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default PlayerFantasyPointsPage;
