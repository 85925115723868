import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Form, Table, Alert, Spinner } from "react-bootstrap";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { getAllRosters } from "../services/roster.service";
import { useTheme } from "../contexts/ThemeContext";
import { useFontSize } from "../contexts/FontSizeContext";
import { useLogger } from "../utils/logger";
import { CapAndTradeInfo } from "../components/CapAndTradeInfo";

export const RosterPage = withLDConsumer()(() => {
  const logger = useLogger();
  const { theme } = useTheme();
  const { fontSize } = useFontSize();
  const { getAccessTokenSilently, user } = useAuth0();

  // Log component initialization
  logger.info("RosterPage initializing", {
    theme,
    fontSize,
    userEmail: user?.email
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rosters, setRosters] = useState({});
  const [teams, setTeams] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedWeek, setSelectedWeek] = useState("");
  const [roster, setRoster] = useState([]);
  const [currentWeek, setCurrentWeek] = useState("");

  const headerFontSize = `${parseFloat(fontSize) * 1.5}px`;

  const fetchAllRosters = useCallback(async () => {
    logger.debug("Fetching all rosters");
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const response = await getAllRosters(token);
      
      logger.debug("Rosters fetched successfully", {
        teamsCount: Object.keys(response.data).length,
        currentWeek: response.currentWeek
      });
      setRosters(response.data);
      setCurrentWeek(response.currentWeek);

      logger.trace("Processing roster data");
      const teamsMap = Object.entries(response.data).map(
        ([email, teamData]) => ({
          email,
          teamName: teamData.tuff_team_name || "Unknown Team",
        })
      );

      const sortedTeams = teamsMap.sort((a, b) =>
        a.teamName.localeCompare(b.teamName)
      );

      setTeams(sortedTeams);

      logger.debug("Teams processed", {
        totalTeams: sortedTeams.length,
        firstTeam: sortedTeams[0]?.teamName
      });

      if (sortedTeams.length > 0) {
        const firstTeamEmail = sortedTeams[0].email;
        const firstTeamWeeks = Object.keys(
          response.data[firstTeamEmail].weeks || {}
        );
        setWeeks(firstTeamWeeks);

        // Set default selected team to user's team
        const userTeam = sortedTeams.find((team) => team.email === user.email);
        if (userTeam) {
          logger.debug("Setting default team to user's team", {
            teamName: userTeam.teamName,
            email: userTeam.email
          });
          setSelectedTeam(userTeam.email);
        }

        // Set default selected week to current week
        if (
          response.currentWeek &&
          firstTeamWeeks.includes(response.currentWeek)
        ) {
          logger.debug("Setting default week to current week", {
            week: response.currentWeek
          });
          setSelectedWeek(response.currentWeek);
        } else if (firstTeamWeeks.length > 0) {
          const lastWeek = firstTeamWeeks[firstTeamWeeks.length - 1];
          logger.debug("Setting default week to last available week", {
            week: lastWeek
          });
          setSelectedWeek(lastWeek);
        }
      }
    } catch (error) {
      logger.error("Failed to fetch rosters", {
        error: error.message,
        stack: error.stack
      });
      setError("Failed to load rosters");
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, user.email, logger]);

  useEffect(() => {
    fetchAllRosters();
  }, [fetchAllRosters]);

  useEffect(() => {
    if (selectedTeam && selectedWeek && rosters[selectedTeam]) {
      const weekData = rosters[selectedTeam].weeks[selectedWeek] || [];
      logger.debug("Updating roster data", {
        team: rosters[selectedTeam].tuff_team_name,
        week: selectedWeek,
        playerCount: weekData.length
      });
      setRoster(weekData);
    }
  }, [selectedTeam, selectedWeek, rosters, logger]);

  const handleTeamChange = useCallback((e) => {
    logger.debug("Team selection changed", {
      previousTeam: selectedTeam,
      newTeam: e.target.value
    });
    setSelectedTeam(e.target.value);
  }, [selectedTeam, logger]);

  const handleWeekChange = useCallback((e) => {
    logger.debug("Week selection changed", {
      previousWeek: selectedWeek,
      newWeek: e.target.value
    });
    setSelectedWeek(e.target.value);
  }, [selectedWeek, logger]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  const renderRosterTable = useCallback(() => {
    logger.trace("Rendering roster table", {
      playerCount: roster.length,
      hasPlayers: roster.length > 0
    });
    return (
      <div className="roster-table-container">
        <Table
          bordered
          striped
          hover
          size="sm"
          variant={theme}
          className="align-middle"
        >
          <thead>
            <tr>
              <th className="roster-position-column">Position</th>
              <th className="roster-player-column">Player Name</th>
              <th className="roster-team-column">Team</th>
              <th className="roster-salary-column">Salary</th>
            </tr>
          </thead>
          <tbody>
            {roster.length > 0 ? (
              roster.map((player, idx) => (
                <tr key={idx}>
                  <td className="roster-position-column">{player.position}</td>
                  <td className="roster-player-column">{player.player_name}</td>
                  <td className="roster-team-column">{player.teamAbv}</td>
                  <td className="roster-salary-column">
                    {formatCurrency(player.salary)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  No players found for selected team and week.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }, [roster, theme, logger]);

  if (loading) {
    logger.debug("Showing loading state");
    return (
      <Container fluid>
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Container>
    );
  }

  logger.debug("Rendering main roster content", {
    hasError: !!error,
    selectedTeam,
    selectedWeek,
    teamsCount: teams.length,
    weeksCount: weeks.length
  });

  return (
    <Container fluid>
      <div className="roster-wrapper">
        <br />
        <h4 style={{ fontSize: headerFontSize }}>TUFF Team Rosters</h4>
        <br />
        {error && <Alert variant="danger">{error}</Alert>}
        <div className="roster-content">
          <div className="roster-form-container">
            <Form.Group className="mb-3">
              <Form.Label>Select Team:</Form.Label>
              <Form.Select value={selectedTeam} onChange={handleTeamChange}>
                <option value="">Choose...</option>
                {teams.map(({ email, teamName }) => (
                  <option key={email} value={email}>
                    {teamName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Select Week:</Form.Label>
              <Form.Select value={selectedWeek} onChange={handleWeekChange}>
                <option value="">Choose...</option>
                {weeks.map((week) => (
                  <option key={week} value={week}>
                    {week === currentWeek ? `${week} (Current)` : week}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
  
          {selectedTeam && selectedWeek && (
            <CapAndTradeInfo
              teamRoster={roster}
              selectedTeam={selectedTeam}
              currentWeek={currentWeek}
              selectedWeek={selectedWeek}
            />
          )}
  
          {renderRosterTable()}
        </div>
      </div>
    </Container>
  );
});

export default RosterPage;
