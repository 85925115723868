import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';

/**
 * VersionChecker Component
 * 
 * This component checks for application version updates by comparing the current version
 * with a version stored in localStorage. If a version mismatch is detected, it displays
 * an alert banner with an update button.
 * 
 * The check is performed on component mount and every 60 seconds thereafter.
 */
export const VersionChecker = () => {
  // State to track if an update is needed
  const [needsUpdate, setNeedsUpdate] = useState(false);
  
  // Get current app version from window object (set during build)
  const currentVersion = window.APP_VERSION;

  useEffect(() => {
    /**
     * Checks if the current version matches the stored version
     * If versions don't match, sets needsUpdate state to true
     * If no stored version or versions match, updates stored version
     */
    const checkVersion = () => {
      const storedVersion = localStorage.getItem('appVersion');
      if (storedVersion && storedVersion !== currentVersion) {
        setNeedsUpdate(true);
      } else {
        localStorage.setItem('appVersion', currentVersion);
      }
    };

    // Perform initial version check when component mounts
    checkVersion();

    // Set up periodic version check every 60 seconds (60000 ms)
    const intervalId = setInterval(checkVersion, 60000);

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, [currentVersion]); // Re-run effect if currentVersion changes

  /**
   * Handles the update button click
   * Updates the stored version and forces a page reload
   * The 'true' parameter forces a reload from server rather than cache
   */
  const handleUpdate = () => {
    localStorage.setItem('appVersion', currentVersion);
    window.location.reload(true);
  };

  // Only render alert if an update is needed
  if (needsUpdate) {
    return (
      <Alert
        variant="warning"
        className="m-0"
        style={{
          position: 'fixed', // Fix alert to top of viewport
          top: 0,
          left: 0,
          right: 0,
          zIndex: 9999, // Ensure alert appears above other content
          borderRadius: 0, // Remove border radius for full-width banner
          textAlign: 'center',
        }}
      >
        <div>
          A new version is available. Please update now to ensure you have the latest features and fixes.
        </div>
        <Button
          variant="primary"
          size="sm"
          onClick={handleUpdate}
          style={{
            marginTop: '0.5rem',
            display: 'block', // Make button take full width
            margin: '0.5rem auto 0', // Center button and add top margin
          }}
        >
          Update Now
        </Button>
      </Alert>
    );
  }

  // Return null if no update is needed (component won't render)
  return null;
};