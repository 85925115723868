import React, { useState, useEffect, useCallback } from "react";
import { Container, Alert, Spinner, Form, Table } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useWebSocket } from "../contexts/WebSocketContext";
import { useTheme } from "../contexts/ThemeContext";
import { useFontSize } from "../contexts/FontSizeContext";
import { useLogger } from "../utils/logger";
import { useParams } from "react-router-dom";

export const FantasyPointsPage = () => {
  const logger = useLogger();
  const { team } = useParams();
  const { theme } = useTheme();
  const { fontSize } = useFontSize();
  const { user } = useAuth0();
  const { fantasyPointsData, connectionStatus, errors, sendMessage } =
    useWebSocket();

  // Log component initialization
  logger.info("FantasyPointsPage initializing", {
    theme,
    fontSize,
    userEmail: user?.email,
    teamParam: team,
  });

  const [selectedWeek, setSelectedWeek] = useState("");
  const [availableWeeks, setAvailableWeeks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRosterPoints, setTotalRosterPoints] = useState(0);
  const [teamNames, setTeamNames] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [pendingRequests, setPendingRequests] = useState(new Set());

  const headerFontSize = `${parseFloat(fontSize) * 1.5}px`;

  const findUserTeam = useCallback(
    (teamNamesData, userEmail) => {
      logger.debug("Finding user's team", { userEmail });
      // teamNamesData is an array of { teamName, email } objects
      const team =
        teamNamesData.find((team) => team.email === userEmail)?.teamName ||
        null;
      logger.trace("User team lookup result", { team });
      return team;
    },
    [logger]
  );

  const requestWeeklyData = useCallback((week, team) => {
    const requestKey = `${week}-${team}`;
    
    // Check if this request is already pending
    if (pendingRequests.has(requestKey)) {
      logger.debug("Skipping duplicate request", { week, team });
      return;
    }
  
    logger.debug("Requesting weekly data", { week, team });
    
    // Add to pending requests
    setPendingRequests(prev => new Set([...prev, requestKey]));
    
    // Send the request
    sendMessage("fantasyPoints", {
      action: "getWeeklyPoints",
      week,
      team
    });
  }, [sendMessage, logger, pendingRequests]);

  const calculateTotalPoints = useCallback((players) => {
    console.log("Calculating total points for players:", players); // Debug log
    return players.reduce((sum, player) => {
      const points = Number(player.totalFantasyPoints) || 0;
      return sum + points;
    }, 0);
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      logger.debug("Initializing fantasy points data");
      if (connectionStatus.fantasyPoints === "OPEN") {
        // Request initial data
        sendMessage("fantasyPoints", { action: "getTeamNames" });
        sendMessage("fantasyPoints", { action: "getCurrentWeek" });
      }
    };

    initializeData();
  }, [connectionStatus.fantasyPoints, logger, sendMessage]);

  // Handle WebSocket data updates
  useEffect(() => {
    if (!fantasyPointsData) return;
  
    logger.debug("Processing WebSocket data update", {
      hasTeamNames: !!fantasyPointsData.teamNames,
      currentWeek: fantasyPointsData.currentWeek,
      selectedWeek,
      hasWeeklyData: !!fantasyPointsData.weeklyStats?.[selectedWeek]?.length,
      weekLoaded: fantasyPointsData.weeklyStatsLoaded?.[selectedWeek],
    });
  
    // Update team names if available
    if (fantasyPointsData.teamNames?.length > 0) {
      logger.debug("Processing team names", {
        teamNames: fantasyPointsData.teamNames,
      });
      setTeamNames(fantasyPointsData.teamNames);
  
      // Set initial selected team
      if (!selectedTeam) {
        let initialTeam;
        if (team) {
          initialTeam = decodeURIComponent(team);
          logger.debug("Using team from URL params", { team: initialTeam });
        } else {
          const userTeam = findUserTeam(
            fantasyPointsData.teamNames,
            user?.email
          );
          initialTeam = userTeam || fantasyPointsData.teamNames[0]?.teamName;
          logger.debug("Setting default team", {
            userTeam,
            initialTeam,
            isUserTeam: !!userTeam,
          });
        }
        setSelectedTeam(initialTeam);
      }
    }
  
    // Update current week and available weeks if available
    if (fantasyPointsData.currentWeek) {
      // Find the latest week that has data
      const weekStats = fantasyPointsData.weeklyStats || {};
      const weeksWithData = Object.keys(weekStats)
        .filter((week) => week.startsWith("Week "))
        .sort((a, b) => {
          const weekA = parseInt(a.replace("Week ", ""));
          const weekB = parseInt(b.replace("Week ", ""));
          return weekB - weekA; // Sort in descending order
        });
  
      const latestWeekWithData = weeksWithData[0];
      if (!latestWeekWithData) {
        logger.warn("No weekly data available");
        return;
      }
  
      // Set up available weeks
      const latestWeekNum = parseInt(latestWeekWithData.replace("Week ", ""));
      if (!isNaN(latestWeekNum)) {
        const weeks = Array.from(
          { length: latestWeekNum },
          (_, i) => `Week ${i + 1}`
        );
        setAvailableWeeks(weeks);
  
        // Set initial selected week to latest week with data if not already set
        if (!selectedWeek) {
          setSelectedWeek(latestWeekWithData);
          // Request initial data if we have both team and week
          if (selectedTeam) {
            const requestKey = `${latestWeekWithData}-${selectedTeam}`;
            if (!pendingRequests.has(requestKey)) {
              logger.debug("Requesting initial data", {
                week: latestWeekWithData,
                team: selectedTeam,
              });
              setPendingRequests(prev => new Set([...prev, requestKey]));
              requestWeeklyData(latestWeekWithData, selectedTeam);
            }
          }
        }
      } else {
        logger.warn("Invalid week number", { latestWeekWithData });
      }
    }
  
    // Update fantasy points data for selected week
    const allWeekData = fantasyPointsData.weeklyStats?.[selectedWeek] || [];
    const isWeekLoaded = fantasyPointsData.weeklyStatsLoaded?.[selectedWeek];
  
    if (allWeekData.length > 0 && isWeekLoaded) {
      // Filter data to only show selected team's roster
      const teamData = allWeekData.filter((player) =>
        player.tuffTeams?.includes(selectedTeam)
      );
      logger.debug("Using weekly data", {
        week: selectedWeek,
        allPlayers: allWeekData.length,
        teamPlayers: teamData.length,
      });
      setTotalRosterPoints(calculateTotalPoints(teamData));
      setLoading(false);
    } else if (selectedWeek && selectedTeam && !isWeekLoaded) {
      const requestKey = `${selectedWeek}-${selectedTeam}`;
      if (!pendingRequests.has(requestKey)) {
        logger.debug("Requesting weekly data", {
          week: selectedWeek,
          team: selectedTeam,
        });
        setPendingRequests(prev => new Set([...prev, requestKey]));
        requestWeeklyData(selectedWeek, selectedTeam);
      } else {
        logger.debug("Weekly data request already pending", {
          week: selectedWeek,
          team: selectedTeam,
        });
      }
    }
  }, [
    fantasyPointsData,
    selectedWeek,
    team,
    user?.email,
    findUserTeam,
    logger,
    calculateTotalPoints,
    selectedTeam,
    requestWeeklyData,
    pendingRequests
  ]);

  useEffect(() => {
    if (!selectedWeek || !selectedTeam) return;
    
    const requestKey = `${selectedWeek}-${selectedTeam}`;
    const isWeekLoaded = fantasyPointsData.weeklyStatsLoaded?.[selectedWeek];
    
    if (isWeekLoaded && pendingRequests.has(requestKey)) {
      logger.debug("Clearing pending request", {
        week: selectedWeek,
        team: selectedTeam,
        requestKey
      });
      setPendingRequests(prev => {
        const next = new Set(prev);
        next.delete(requestKey);
        return next;
      });
    }
  }, [fantasyPointsData.weeklyStatsLoaded, selectedWeek, selectedTeam, pendingRequests, logger]);

  const handleWeekChange = useCallback((newWeek) => {
    logger.debug("Week change requested", {
      newWeek,
      hasExistingData: !!fantasyPointsData.weeklyStats?.[newWeek]
    });
  
    setSelectedWeek(newWeek);
    
    if (!fantasyPointsData.weeklyStats?.[newWeek]) {
      setLoading(true);
      requestWeeklyData(newWeek, selectedTeam);
    }
  }, [fantasyPointsData.weeklyStats, selectedTeam, requestWeeklyData, logger]);

  const handleTeamChange = useCallback((newTeam) => {
    logger.debug("Team selection changed", {
      previousTeam: selectedTeam,  // selectedTeam is used here
      newTeam
    });
    
    setSelectedTeam(newTeam);
    setLoading(true);
  
    const allWeekData = fantasyPointsData.weeklyStats?.[selectedWeek] || [];
    const isWeekLoaded = fantasyPointsData.weeklyStatsLoaded?.[selectedWeek];
  
    if (!(allWeekData.length > 0 && isWeekLoaded)) {
      requestWeeklyData(selectedWeek, newTeam);
    }
  }, [selectedWeek, requestWeeklyData, fantasyPointsData, logger, selectedTeam]);  // Added selectedTeam here

  const renderFantasyPointsTable = useCallback(() => {
    const weekData = fantasyPointsData.weeklyStats?.[selectedWeek] || [];
    const weekNumber = parseInt(selectedWeek?.split(" ")[1]);
    const isPlayoffWeek = weekNumber >= 15;

    logger.debug("Processing week data", {
      weekNumber,
      isPlayoffWeek,
      dataLength: weekData.length,
      selectedTeam,
    });

    // Filter data and ensure fantasy points are processed as numbers
    const data = weekData
      .filter((player) => {
        const hasPoints = Number(player.totalFantasyPoints) > 0;
        return player.tuffTeams?.includes(selectedTeam) && hasPoints;
      })
      .map((player) => ({
        ...player,
        totalFantasyPoints: Math.floor(Number(player.totalFantasyPoints)),
        teamAbv: player.team || player.teamAbv,
      }))
      .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints);

    const points = data.reduce(
      (sum, player) => sum + player.totalFantasyPoints,
      0
    );

    return (
      <div className="fantasy-points-table-container">
        <Table
          striped
          bordered
          hover
          size="sm"
          variant={theme}
          className="align-middle"
        >
          <thead>
            <tr>
              <th className="fantasy-position-column">Position</th>
              <th className="fantasy-player-column">Player</th>
              <th className="fantasy-team-column">NFL Team</th>
              <th className="fantasy-points-column">Fantasy Points</th>
            </tr>
          </thead>
          <tbody>
            {data.map((player, index) => (
              <tr key={`${player.playerID}-${index}`}>
                <td className="fantasy-position-column">{player.position}</td>
                <td className="fantasy-player-column">{player.playerName}</td>
                <td className="fantasy-team-column">{player.teamAbv}</td>
                <td className="fantasy-points-column">
                  {player.totalFantasyPoints}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="fantasy-points-total">
          <h4>Total Roster Fantasy Points: {points}</h4>
        </div>
      </div>
    );
  }, [fantasyPointsData, selectedWeek, selectedTeam, theme, logger]);

  if (loading || connectionStatus.fantasyPoints === "CONNECTING") {
    logger.debug("Showing loading state", {
      loading,
      connectionStatus: connectionStatus.fantasyPoints,
    });
    return (
      <Container fluid>
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Container>
    );
  }

  logger.debug("Rendering main fantasy points content", {
    hasError: !!errors?.fantasyPoints,
    selectedTeam,
    selectedWeek,
    teamsCount: teamNames.length,
    weeksCount: availableWeeks.length,
    totalPoints: totalRosterPoints,
  });

  return (
    <Container fluid>
      <div className="fantasy-points-wrapper">
        <br />
        <h4 style={{ fontSize: headerFontSize }}>Weekly Fantasy Points</h4>
        <br />
        {errors?.fantasyPoints && (
          <Alert variant="danger">{errors.fantasyPoints}</Alert>
        )}
        <div className="fantasy-points-content">
          <div className="fantasy-points-form-container">
            <Form.Group className="mb-3">
              <Form.Label>Select Team:</Form.Label>
              <Form.Select
                value={selectedTeam}
                onChange={(e) => handleTeamChange(e.target.value)}
              >
                {teamNames.map((team) => (
                  <option key={team.email} value={team.teamName}>
                    {team.teamName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Select Week:</Form.Label>
              <Form.Select
                value={selectedWeek}
                onChange={(e) => handleWeekChange(e.target.value)}
              >
                {availableWeeks.map((week) => (
                  <option key={week} value={week}>
                    {week}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
          {renderFantasyPointsTable()}
        </div>
      </div>
    </Container>
  );
};

export default FantasyPointsPage;
