// External library imports
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import { CaretUpFill, CaretDownFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

// Internal components and context imports
import { useTheme } from "../contexts/ThemeContext";
import { useFontSize } from "../contexts/FontSizeContext";
import { useLogger } from "../utils/logger";
import { useWebSocket } from "../contexts/WebSocketContext";

/**
 * DashboardPage Component
 *
 * A comprehensive dashboard displaying fantasy football statistics including:
 * - Team standings (season/playoff and weekly)
 * - Player rankings (overall and by position)
 * - Real-time updates via WebSocket
 */
export const DashboardPage = withLDConsumer()(({ flags }) => {
  // Hook initializations
  const logger = useLogger();
  const { user } = useAuth0();
  const { theme } = useTheme();
  const { fontSize } = useFontSize();
  const {
    standingsData,
    fantasyPointsData,
    errors: wsErrors,
    connectionStatus,
    sendMessage,
  } = useWebSocket();
  const currentSeason = process.env.REACT_APP_CURRENT_SEASON;

  // Log component initialization
  logger.info("DashboardPage initializing", {
    theme,
    fontSize,
    currentSeason,
    userEmail: user?.email,
  });

  // State initialization
  const [dashboardData, setDashboardData] = useState({
    topSeasonTeams: [],
    topWeekTeams: [],
    topSeasonPlayers: [],
    topWeekPlayers: [],
    topPositionPlayers: {
      QB: { season: [], week: [] },
      RB: { season: [], week: [] },
      WR: { season: [], week: [] },
      TE: { season: [], week: [] },
      DEF: { season: [], week: [] },
    },
    currentNFLWeek: 15,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Memoized values
  const positions = useMemo(() => {
    logger.debug("Initializing positions array");
    return ["QB", "RB", "WR", "TE", "DEF"];
  }, [logger]);

  // Helper functions and memoized checks
  const hasStandingsData = useMemo(() => {
    return (
      standingsData &&
      (standingsData.regularSeason?.length > 0 ||
        standingsData.playoffs?.length > 0)
    );
  }, [standingsData]);

  const isPlayoffWeek = useCallback(
    (week) => {
      const isPlayoff = week >= 15 && week <= 18;
      logger.debug("Checking playoff week status", { week, isPlayoff });
      return isPlayoff;
    },
    [logger]
  );

  const areAllPlayoffWeeksLoaded = useCallback(() => {
    if (!standingsData?.currentNFLWeek) {
      logger.debug("No current NFL week available");
      return false;
    }

    const weekLoadStatus = {};
    for (let week = 15; week <= standingsData.currentNFLWeek; week++) {
      const weekKey = `Week ${week}`;
      const isLoaded = fantasyPointsData.weeklyStatsLoaded?.[weekKey];
      weekLoadStatus[weekKey] = isLoaded;

      if (!isLoaded) {
        logger.debug("Playoff weeks loading status:", {
          currentNFLWeek: standingsData.currentNFLWeek,
          weekLoadStatus,
          missingWeek: weekKey,
        });
        return false;
      }
    }

    logger.debug("All playoff weeks loaded:", {
      currentNFLWeek: standingsData.currentNFLWeek,
      weekLoadStatus,
    });
    return true;
  }, [
    standingsData?.currentNFLWeek,
    fantasyPointsData.weeklyStatsLoaded,
    logger,
  ]);

  // Data loading check
  const isDataFullyLoaded = useCallback(() => {
    // Check connections
    if (
      connectionStatus.standings === "CONNECTING" ||
      connectionStatus.fantasyPoints === "CONNECTING"
    ) {
      logger.debug("Waiting for connections", connectionStatus);
      return false;
    }
  
    // Check basic data requirements
    if (!hasStandingsData || !fantasyPointsData.teamNames?.length) {
      logger.debug("Waiting for basic data", {
        hasStandingsData,
        hasTeamNames: !!fantasyPointsData.teamNames?.length,
      });
      return false;
    }
  
    // Check current week
    const currentWeek = standingsData?.currentNFLWeek;
    if (!currentWeek) {
      logger.debug("Waiting for current week data");
      return false;
    }
  
    // For playoff weeks (15-17), ensure we have all playoff week data
    if (isPlayoffWeek(currentWeek)) {
      // Check if we have data for all weeks from 15 to current
      for (let week = 15; week <= currentWeek; week++) {
        const weekKey = `Week ${week}`;
        if (!fantasyPointsData.weeklyStats?.[weekKey]?.length) {
          logger.debug(`Missing data for ${weekKey}`);
          return false;
        }
      }
    } else {
      // For regular season, we just need the current week's data
      const weekKey = `Week ${currentWeek}`;
      if (!fantasyPointsData.weeklyStats?.[weekKey]?.length) {
        logger.debug(`Missing data for current week ${weekKey}`);
        return false;
      }
    }
  
    return true;
  }, [
    connectionStatus,
    hasStandingsData,
    fantasyPointsData.teamNames,
    fantasyPointsData.weeklyStats,
    standingsData?.currentNFLWeek,
    isPlayoffWeek,
    logger,
  ]);

  // Rest of your component logic follows...

  /**
   * Updates dashboard data while maintaining state structure
   */
  const updateDashboardData = useCallback(
    (newData) => {
      logger.trace("Updating dashboard data", {
        dataKeys: Object.keys(newData),
        hasTeamData: !!newData.topSeasonTeams,
        hasPlayerData: !!newData.topSeasonPlayers,
      });

      setDashboardData((prev) => {
        const updated = {
          ...prev,
          ...newData,
        };
        logger.trace("Dashboard data updated", {
          teamCount: updated.topSeasonTeams?.length,
          playerCount: updated.topSeasonPlayers?.length,
          currentWeek: updated.currentNFLWeek,
        });
        return updated;
      });
    },
    [logger]
  );

  /**
   * Processes team data from WebSocket updates
   */
  const processTeamData = useCallback(() => {
    let teamsData = isPlayoffWeek(standingsData.currentNFLWeek)
      ? standingsData.playoffs
      : standingsData.regularSeason;

    const processedTeams = teamsData.map((team) => ({
      managerEmail: team.managerEmail,
      teamName: team.teamName,
      rankings: team.rankings,
      totalPoints: isPlayoffWeek(standingsData.currentNFLWeek)
        ? Object.entries(team.weeklyPoints)
            .filter(([week]) => parseInt(week.split(" ")[1]) > 14)
            .reduce((sum, [, points]) => sum + points, 0)
        : team.totalPoints,
      weekPoints:
        team.weeklyPoints[`Week ${standingsData.currentNFLWeek}`] || 0,
    }));

    const topSeason = [...processedTeams].sort(
      (a, b) => b.totalPoints - a.totalPoints
    );
    const topWeek = [...processedTeams].sort(
      (a, b) => b.weekPoints - a.weekPoints
    );

    return { topSeason, topWeek };
  }, [standingsData, isPlayoffWeek]);

  /**
   * Effect: Update standings when WebSocket data changes
   */
  useEffect(() => {
    if (standingsData) {
      const { topSeason, topWeek } = processTeamData();
      updateDashboardData({
        currentNFLWeek: standingsData.currentNFLWeek,
        topSeasonTeams: topSeason,
        topWeekTeams: topWeek,
      });
    }
  }, [standingsData, processTeamData, updateDashboardData]);

  /**
   * Effect: Process fantasy points data from WebSocket
   */
  useEffect(() => {
    if (!fantasyPointsData) return;

    try {
      if (isPlayoffWeek(standingsData?.currentNFLWeek)) {
        // Only process data when all playoff weeks are loaded
        if (!areAllPlayoffWeeksLoaded()) {
          logger.debug("Waiting for all playoff weeks to load");
          return;
        }

        logger.debug("Processing playoff data - all weeks loaded");

        // Process weekly data
        const weeklyData =
          fantasyPointsData.weeklyStats?.[
            `Week ${standingsData.currentNFLWeek}`
          ] || [];
        const weeklyProcessedPlayers = weeklyData
          .filter((player) => Number(player.totalFantasyPoints) > 0)
          .map((player) => ({
            playerID: player.playerID,
            playerName: player.playerName,
            position: player.position,
            teamAbv: player.team || player.teamAbv,
            totalFantasyPoints: Number(player.totalFantasyPoints),
          }))
          .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints);

        // Process cumulative playoff data
        const playoffWeeks = Object.entries(
          fantasyPointsData.weeklyStats
        ).filter(([week]) => {
          const weekNum = parseInt(week.split(" ")[1]);
          return weekNum >= 15 && weekNum <= standingsData.currentNFLWeek;
        });

        const playerTotals = new Map();
        playoffWeeks.forEach(([, weekData]) => {
          weekData.forEach((player) => {
            const key = `${player.playerID}-${player.playerName}`;
            if (!playerTotals.has(key)) {
              playerTotals.set(key, {
                playerID: player.playerID,
                playerName: player.playerName,
                position: player.position,
                teamAbv: player.team || player.teamAbv,
                totalFantasyPoints: 0,
              });
            }
            const current = playerTotals.get(key);
            current.totalFantasyPoints += Number(player.totalFantasyPoints);
          });
        });

        const cumulativeProcessedPlayers = Array.from(
          playerTotals.values()
        ).sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints);

        // Update dashboard with processed data
        updateDashboardData({
          topWeekPlayers: weeklyProcessedPlayers.slice(0, 10),
          topSeasonPlayers: cumulativeProcessedPlayers.slice(0, 10),
          topPositionPlayers: positions.reduce((acc, position) => {
            const weeklyFiltered = weeklyProcessedPlayers.filter(
              (player) => player.position === position
            );
            const seasonFiltered = cumulativeProcessedPlayers.filter(
              (player) => player.position === position
            );
            acc[position] = {
              week: weeklyFiltered.slice(0, 10),
              season: seasonFiltered.slice(0, 10),
            };
            return acc;
          }, {}),
        });

        setLoading(false);
      }
    } catch (err) {
      logger.error("Failed to process fantasy points data", {
        error: err.message,
        stack: err.stack,
      });
      setError(`Error processing fantasy points data: ${err.message}`);
    }
  }, [
    fantasyPointsData,
    standingsData?.currentNFLWeek,
    positions,
    updateDashboardData,
    isPlayoffWeek,
    logger,
    areAllPlayoffWeeksLoaded,
  ]);

  /**
   * Effect: Initial data fetch for fantasy points
   */
  useEffect(() => {
    if (
      connectionStatus.fantasyPoints === "OPEN" &&
      standingsData?.currentNFLWeek
    ) {
      const currentWeek = standingsData.currentNFLWeek;
      
      if (isPlayoffWeek(currentWeek)) {
        // Request data for all playoff weeks (15 through current)
        for (let week = 15; week <= currentWeek; week++) {
          sendMessage("fantasyPoints", {
            action: "getWeeklyPoints",
            week: `Week ${week}`,
          });
        }
      } else {
        // Just request the current week for regular season
        sendMessage("fantasyPoints", {
          action: "getWeeklyPoints",
          week: `Week ${currentWeek}`,
        });
      }
    }
  }, [
    connectionStatus.fantasyPoints,
    standingsData?.currentNFLWeek,
    isPlayoffWeek,
    sendMessage,
  ]);

  /**
   * Renders a standings/team table
   */
  const renderTeamTable = useCallback(
    (title, data, pointsKey) => {
      logger.trace("Rendering team table", {
        title,
        teamsCount: data?.length,
        pointsKey,
      });

      // Check if this is the playoff standings table
      const isPlayoffTable =
        title.includes("Playoffs") ||
        (isPlayoffWeek(dashboardData.currentNFLWeek) &&
          !title.includes("Week"));

      const getTableTitle = () => {
        const baseTitle = title.includes("Week")
          ? `Fantasy Points - Week ${dashboardData.currentNFLWeek}`
          : `Fantasy Points ${
              isPlayoffWeek(dashboardData.currentNFLWeek)
                ? "- Playoffs"
                : "- Season"
            }`;
        return baseTitle;
      };

      return (
        <div className="dashboard-table-container">
          <h4
            className="dashboard-table-title"
            title={getTableTitle()}
            style={{ fontSize: `calc(${fontSize}px * 1.5)` }}
          >
            {getTableTitle()}
          </h4>
          <div className="table-responsive">
            <Table
              striped
              bordered
              hover
              size="sm"
              variant={theme}
              className="align-middle"
            >
              <thead>
                <tr>
                  <th className="dashboard-rank-column">Rank</th>
                  {isPlayoffTable && (
                    <th className="dashboard-rank-change-column"></th>
                  )}
                  <th className="dashboard-team-column">Team</th>
                  <th className="dashboard-points-column">Points</th>
                </tr>
              </thead>
              <tbody>
                {data.map((team, index) => {
                  let trendIcon = null;
                  if (
                    isPlayoffTable &&
                    standingsData.currentNFLWeek > 1 &&
                    team.rankings?.previousRank !== team.rankings?.currentRank
                  ) {
                    if (
                      team.rankings?.previousRank > team.rankings?.currentRank
                    ) {
                      trendIcon = (
                        <CaretUpFill className="text-success" size={12} />
                      );
                    } else {
                      trendIcon = (
                        <CaretDownFill className="text-danger" size={12} />
                      );
                    }
                  }

                  return (
                    <tr key={team.managerEmail}>
                      <td className="dashboard-rank-column">{index + 1}</td>
                      {isPlayoffTable && (
                        <td className="dashboard-rank-change-column text-center">
                          {trendIcon}
                        </td>
                      )}
                      <td className="dashboard-team-column">
                        <Link
                          to={`/fantasypoints/${encodeURIComponent(
                            team.teamName || team.managerEmail
                          )}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {team.teamName || team.managerEmail}
                        </Link>
                      </td>
                      <td className="dashboard-points-column">
                        {pointsKey === "weekPoints"
                          ? team.weekPoints
                          : team[pointsKey]}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      );
    },
    [
      fontSize,
      theme,
      dashboardData.currentNFLWeek,
      isPlayoffWeek,
      standingsData.currentNFLWeek,
      logger,
    ]
  );

  /**
   * Renders a player statistics table
   */
  const renderPlayerTable = useCallback(
    (title, data) => {
      logger.trace("Rendering player table", {
        title,
        playersCount: data?.length,
      });

      return (
        <div className="dashboard-table-container">
          <h4
            className="dashboard-table-title"
            title={title}
            style={{ fontSize: `calc(${fontSize}px * 1.5)` }}
          >
            {title}
          </h4>
          <Table
            striped
            bordered
            hover
            size="sm"
            variant={theme}
            className="align-middle"
          >
            <thead>
              <tr>
                <th>Rank</th>
                <th>Player Name</th>
                <th>NFL Team</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {data.map((player, index) => (
                <tr key={player.playerId || index}>
                  <td>{index + 1}</td>
                  <td>{player.playerName}</td>
                  <td>{player.teamAbv}</td>
                  <td>{player.totalFantasyPoints}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    },
    [fontSize, theme, logger]
  );

  /**
   * Renders position-specific tables in tabs
   */
  const renderPositionTables = useCallback(() => {
    logger.trace("Rendering position-specific tables", {
      positionsCount: positions.length,
      hasPositionData: !!dashboardData.topPositionPlayers,
    });

    // Helper function for title generation
    const getPositionTitle = (position, isWeekly = false) => {
      if (isWeekly) {
        return `Top ${position} - Week ${dashboardData.currentNFLWeek}`;
      }
      return `Top ${position} ${
        isPlayoffWeek(dashboardData.currentNFLWeek) ? "- Playoffs" : "- Season"
      }`;
    };

    return (
      <Tabs defaultActiveKey="QB" id="position-tabs" className="mb-3">
        {positions.map((position) => {
          logger.trace("Rendering position tab", {
            position,
            seasonPlayers:
              dashboardData.topPositionPlayers[position]?.season?.length,
            weekPlayers:
              dashboardData.topPositionPlayers[position]?.week?.length,
          });

          return (
            <Tab eventKey={position} title={position} key={position}>
              <Row>
                <Col md={6}>
                  {renderPlayerTable(
                    getPositionTitle(position),
                    dashboardData.topPositionPlayers[position]?.season || []
                  )}
                </Col>
                <Col md={6}>
                  {renderPlayerTable(
                    getPositionTitle(position, true),
                    dashboardData.topPositionPlayers[position]?.week || []
                  )}
                </Col>
              </Row>
            </Tab>
          );
        })}
      </Tabs>
    );
  }, [
    positions,
    dashboardData.topPositionPlayers,
    renderPlayerTable,
    dashboardData.currentNFLWeek,
    isPlayoffWeek,
    logger,
  ]);

  /**
   * Loading state render
   */
  if (loading || !isDataFullyLoaded()) {
    const loadingReason = loading
      ? "Initial loading"
      : !hasStandingsData
      ? "Waiting for standings data"
      : connectionStatus.standings === "CONNECTING"
      ? "Standings connecting"
      : connectionStatus.fantasyPoints === "CONNECTING"
      ? "Fantasy points connecting"
      : "Processing data";

    logger.info("Loading state", { reason: loadingReason });
    logger.debug("Loading state details", {
      loading,
      standingsStatus: connectionStatus.standings,
      fantasyPointsStatus: connectionStatus.fantasyPoints,
      hasStandingsData,
      currentNFLWeek: standingsData?.currentNFLWeek,
      weeklyStatsLoaded: fantasyPointsData.weeklyStatsLoaded,
      positionsLoaded: dashboardData.topPositionPlayers,
    });

    return (
      <Container fluid>
        <div
          className="text-center"
          style={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Container>
    );
  }

  /**
   * Feature flag check
   */
  if (!flags.dashboardComponent) {
    logger.info("Dashboard component disabled via feature flag");
    return (
      <Container fluid>
        <br />
        <h4 style={{ fontSize: `calc(${fontSize}px * 1.5)` }}>
          TUFF Dashboard
        </h4>
        <br />
        <h1>The TUFF Dashboard is currently disabled.</h1>
      </Container>
    );
  }

  /**
   * Main dashboard render
   */
  logger.info("Rendering main dashboard content", {
    currentWeek: dashboardData.currentNFLWeek,
    isPlayoff: isPlayoffWeek(dashboardData.currentNFLWeek),
    hasError: !!error,
  });

  return (
    <div className="dashboard-wrapper">
      <Container fluid>
        <br />
        <h4 style={{ fontSize: `calc(${fontSize}px * 1.5)` }}>
          TUFF Dashboard{" "}
          {isPlayoffWeek(dashboardData.currentNFLWeek) ? "- Playoffs" : ""}
        </h4>
        <br />

        {error && <Alert variant="danger">{error}</Alert>}
        {wsErrors?.standings && (
          <Alert variant="danger">{wsErrors.standings}</Alert>
        )}
        {wsErrors?.fantasyPoints && (
          <Alert variant="danger">{wsErrors.fantasyPoints}</Alert>
        )}

        <div className="dashboard-content">
          {hasStandingsData ? (
            <>
              <Row className="justify-content-center">
                <Col md={6}>
                  {renderTeamTable(
                    "Fantasy Points",
                    dashboardData.topSeasonTeams,
                    "totalPoints"
                  )}
                </Col>
                <Col md={6}>
                  {renderTeamTable(
                    "Fantasy Points - Week",
                    dashboardData.topWeekTeams,
                    "weekPoints"
                  )}
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={6}>
                  {renderPlayerTable(
                    `Top Players ${
                      isPlayoffWeek(dashboardData.currentNFLWeek)
                        ? "- Playoffs"
                        : "- Season"
                    }`,
                    dashboardData.topSeasonPlayers
                  )}
                </Col>
                <Col md={6}>
                  {renderPlayerTable(
                    `Top Players - Week ${dashboardData.currentNFLWeek}`,
                    dashboardData.topWeekPlayers
                  )}
                </Col>
              </Row>

              <section className="position-details">
                {renderPositionTables()}
              </section>
            </>
          ) : (
            <div
              className="text-center"
              style={{
                minHeight: "calc(100vh - 100px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">
                  Loading standings data...
                </span>
              </Spinner>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
});

// Export with memo for performance optimization
export default React.memo(DashboardPage);
