// Import core React library
import React from "react";
// Import createRoot API for React 18's concurrent rendering
import { createRoot } from "react-dom/client";
// Import BrowserRouter for client-side routing
import { BrowserRouter } from "react-router-dom";

// Import custom context providers
import { Auth0ProviderWithNavigate } from "./contexts/auth0-provider-with-navigate"; // Authentication provider
import { LDProvider } from "./contexts/LaunchDarklyContext"; // Feature flag management
import { ThemeProvider } from "./contexts/ThemeContext"; // Theme/styling management
import { FontSizeProvider } from "./contexts/FontSizeContext"; // Font size preferences
import { WebSocketProvider } from "./contexts/WebSocketContext"; // Real-time communication

// Import main App component
import { App } from "./app";

// Import global styles
import "./styles/styles.css";

// Import polyfills for IE11 compatibility
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

// Get root DOM element where React will mount the application
const container = document.getElementById("root");
// Create a root for concurrent rendering
const root = createRoot(container);

// Check if app is running in production environment
const isProduction = process.env.REACT_APP_ENVIRONMENT === "production";

// Define main app structure with nested context providers
const AppContent = (
  <BrowserRouter>                    {/* Enables routing */}
    <Auth0ProviderWithNavigate>      {/* Authentication context */}
      <LDProvider>                   {/* Feature flags context */}
        <ThemeProvider>              {/* Theme context */}
          <FontSizeProvider>         {/* Font size context */}
            <WebSocketProvider>      {/* WebSocket context */}
              <App />                {/* Main application component */}
            </WebSocketProvider>
          </FontSizeProvider>
        </ThemeProvider>
      </LDProvider>
    </Auth0ProviderWithNavigate>
  </BrowserRouter>
);

// Render the application
// In development, wrap with StrictMode for additional checks
// In production, render without StrictMode for better performance
root.render(
  isProduction ? AppContent : <React.StrictMode>{AppContent}</React.StrictMode>
);