// WebSocketContext.js
import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import { useLogger } from "../utils/logger";

// Define initial context shape with default values
const WebSocketContext = createContext({
  newsData: [], // Stores all news items
  latestNewsData: [], // Stores most recent news
  standingsData: {
    // League standings data structure
    regularSeason: [],
    playoffs: [],
    currentNFLWeek: 1,
    weeklyRankings: {},
  },
  fantasyPointsData: {
    // Fantasy points data structure
    allPlayers: [], // All players' total points
    weeklyStats: {}, // Weekly stats indexed by week
    teamNames: {}, // Team name mappings
    seasonTotal: null, // Season total statistics
    currentWeek: 1, // Current NFL week
  },
  errors: {
    // Error state for each service
    news: null,
    latestNews: null,
    standings: null,
    fantasyPoints: null,
  },
  connectionStatus: {
    // WebSocket connection states
    news: "CONNECTING",
    latestNews: "CONNECTING",
    standings: "CONNECTING",
    fantasyPoints: "CONNECTING",
  },
  sendMessage: () => Promise.reject(new Error("WebSocket not initialized")),
});

export const WebSocketProvider = withLDConsumer()(({ flags, children }) => {
  const logger = useLogger();
  logger.debug("WebSocketProvider initializing");

  // State declarations for different data types
  const [newsData, setNewsData] = useState([]);
  const [latestNewsData, setLatestNewsData] = useState([]);

  // Standings data with playoffs and rankings
  const [standingsData, setStandingsData] = useState({
    regularSeason: [],
    playoffs: [],
    currentNFLWeek: 1,
    weeklyRankings: {},
  });

  // Fantasy points state with chunk tracking
  const [fantasyPointsData, setFantasyPointsData] = useState({
    allPlayers: [],
    weeklyStats: {},
    teamNames: {},
    seasonTotal: null,
    currentWeek: 1,
    receivedChunks: {},
    totalChunks: 0,
    allPlayersFullyLoaded: false,
    totalItems: 0,
    receivedWeeklyChunks: {},
    weeklyStatsLoaded: {},
    seasonPositionLeaders: null,
    weeklyPositionLeaders: {},
  });

  // Error and connection status management
  const [errors, setErrors] = useState({
    news: null,
    latestNews: null,
    standings: null,
  });

  const [connectionStatus, setConnectionStatus] = useState({
    news: "CONNECTING",
    latestNews: "CONNECTING",
    standings: "CONNECTING",
    fantasyPoints: "CONNECTING",
    fantasyPointsLoading: true,
  });

  // Track connection attempts for backoff
const connectionAttempts = useRef({
  news: 0,
  latestNews: 0,
  standings: 0,
  fantasyPoints: 0
});

// WebSocket connection references
const wsRefs = useRef({
  news: null,
  latestNews: null,
  standings: null,
  fantasyPoints: null
});

// Timeout references for connection management
const timeoutRefs = useRef({
  news: null,
  latestNews: null,
  standings: null,
  fantasyPoints: null
});

  // Auth0 hooks
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  // Error handling functions
  const setError = (type, errorMessage) => {
    setErrors((prev) => ({ ...prev, [type]: errorMessage }));
  };

  const clearError = (type) => {
    setErrors((prev) => ({ ...prev, [type]: null }));
  };

  const clearTimeouts = useCallback((type) => {
    if (timeoutRefs.current[type]) {
      clearTimeout(timeoutRefs.current[type]);
      timeoutRefs.current[type] = null;
    }
  }, []);

  // Main WebSocket connection handler
  const connectWebSocket = useCallback(
    async (type) => {
      logger.debug(
        `Initiating ${type} WebSocket connection - Attempt ${
          connectionAttempts.current[type] + 1
        }`
      );
      clearTimeouts(type);
      clearError(type);

      if (!isAuthenticated) {
        logger.debug(`${type}: User not authenticated, skipping connection`);
        return;
      }

      // Determine WebSocket URL based on connection type
      const baseWsUrl = (() => {
        switch (type) {
          case "news":
            return process.env.REACT_APP_NEWS_WEBSOCKET_API_URL;
          case "latestNews":
            return process.env.REACT_APP_LATEST_NEWS_WEBSOCKET_API_URL;
          case "standings":
            return process.env.REACT_APP_STANDINGS_WEBSOCKET_API_URL;
          case "fantasyPoints":
            return process.env.REACT_APP_FANTASY_POINTS_WEBSOCKET_API_URL;
          default:
            return null;
        }
      })();

      if (!baseWsUrl) {
        const errorMsg = `Missing WebSocket URL for ${type}`;
        logger.error(errorMsg);
        setError(type, errorMsg);
        return;
      }

      try {
        logger.debug(`${type}: Getting access token`);
        const accessToken = await getAccessTokenSilently();
        const clientId = `web-${type}-${Date.now()}`;
        const wsUrl = `${baseWsUrl}?access_token=${accessToken}&clientId=${clientId}`;

        // Close existing connection if open
        if (wsRefs.current[type]?.readyState === WebSocket.OPEN) {
          wsRefs.current[type].close();
        }

        // Create new WebSocket connection
        const ws = new WebSocket(wsUrl);
        wsRefs.current[type] = ws;

        // Set connection timeout
        timeoutRefs.current[type] = setTimeout(() => {
          if (ws.readyState === WebSocket.CONNECTING) {
            ws.close();
          }
        }, 10000);

        // WebSocket open handler
        ws.onopen = () => {
          clearTimeouts(type);
          connectionAttempts.current[type] = 0;
          setConnectionStatus((prev) => ({ ...prev, [type]: "OPEN" }));

          // Initial data request handler
          const sendInitialRequest = async (retries = 3) => {
            if (type === "news" || type === "latestNews") {
              try {
                ws.send(
                  JSON.stringify({
                    action: "getNFLNews",
                    type: "fetchAll",
                  })
                );
              } catch (err) {
                if (retries > 0 && ws.readyState === WebSocket.OPEN) {
                  setTimeout(() => sendInitialRequest(retries - 1), 1000);
                }
              }
            } else if (type === "standings") {
              try {
                ws.send(JSON.stringify({ action: "getStandings" }));
              } catch (err) {
                if (retries > 0 && ws.readyState === WebSocket.OPEN) {
                  setTimeout(() => sendInitialRequest(retries - 1), 1000);
                }
              }
            } else if (type === "fantasyPoints") {
              try {
                ws.send(JSON.stringify({ action: "getTeamNames" }));
                ws.send(JSON.stringify({ action: "getAvailableWeeks" }));
              } catch (err) {
                if (retries > 0 && ws.readyState === WebSocket.OPEN) {
                  setTimeout(() => sendInitialRequest(retries - 1), 1000);
                }
              }
            }
          };

          sendInitialRequest();
        };

        // Message handler for incoming WebSocket data
        ws.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            logger.debug(`${type} received raw message:`, data);

            if (type === "standings" && data.type === "standings") {
              setStandingsData({
                regularSeason: data.data.regularSeasonData || [],
                playoffs:
                  data.data.currentNFLWeek > 14 ? data.data.playoffData : [],
                currentNFLWeek: data.data.currentNFLWeek,
                weeklyRankings: data.data.weeklyRankings || {},
              });
            } else if (data.type === "nflnews") {
              const newsItems = Array.isArray(data.news) ? data.news : [];
              if (type === "news") setNewsData(newsItems);
              else if (type === "latestNews") setLatestNewsData(newsItems);
            } else if (type === "fantasyPoints") {
              logger.debug("Received fantasy points message:", {
                action: data.action,
                hasData: !!data.data,
                raw: data,
              });

              switch (data.action) {
                case "getAllPoints":
                  setFantasyPointsData((prev) => ({
                    ...prev,
                    receivedChunks: {
                      ...(prev.receivedChunks || {}),
                      [data.chunkIndex]: data.data,
                    },
                  }));
                  break;

                case "getAllPointsComplete":
                  setFantasyPointsData((prev) => {
                    const chunks = prev.receivedChunks || {};
                    const orderedChunks = Object.keys(chunks)
                      .sort((a, b) => parseInt(a) - parseInt(b))
                      .map((key) => chunks[key]);

                    return {
                      ...prev,
                      allPlayers: orderedChunks.flat(),
                      allPlayersFullyLoaded: true,
                      totalItems: data.totalItems,
                      receivedChunks: {},
                    };
                  });
                  break;

                case "getPositionLeaders":
                  setFantasyPointsData((prev) => ({
                    ...prev,
                    seasonPositionLeaders: data.data,
                  }));
                  break;

                case "getWeeklyPositionLeaders":
                  const weekForLeaders = data.params?.week;
                  if (!weekForLeaders) {
                    logger.error(
                      "No week specified for position leaders:",
                      data
                    );
                    break;
                  }
                  setFantasyPointsData((prev) => ({
                    ...prev,
                    weeklyPositionLeaders: {
                      ...prev.weeklyPositionLeaders,
                      [weekForLeaders]: data.data,
                    },
                  }));
                  break;

                case "getWeeklyPoints":
                  const weekToUse = data.params?.week;
                  if (!weekToUse) {
                    logger.error("No week found in weekly points data:", data);
                    break;
                  }

                  setFantasyPointsData((prev) => ({
                    ...prev,
                    receivedWeeklyChunks: {
                      ...prev.receivedWeeklyChunks,
                      [weekToUse]: {
                        ...(prev.receivedWeeklyChunks?.[weekToUse] || {}),
                        [data.chunkIndex]: data.data,
                      },
                    },
                  }));
                  break;

                case "getWeeklyPointsComplete":
                  const completedWeek = data.params?.week;
                  if (!completedWeek) {
                    logger.error("No week found in complete message:", data);
                    break;
                  }

                  setFantasyPointsData((prev) => {
                    const weekChunks =
                      prev.receivedWeeklyChunks?.[completedWeek] || {};
                    const combinedWeeklyStats = Object.keys(weekChunks)
                      .sort((a, b) => parseInt(a) - parseInt(b))
                      .flatMap((chunkIndex) => weekChunks[chunkIndex]);

                    logger.debug(
                      `Combining ${
                        Object.keys(weekChunks).length
                      } chunks for week ${completedWeek}`
                    );

                    return {
                      ...prev,
                      weeklyStats: {
                        ...prev.weeklyStats,
                        [completedWeek]: combinedWeeklyStats,
                      },
                      weeklyStatsLoaded: {
                        ...prev.weeklyStatsLoaded,
                        [completedWeek]: true,
                      },
                      receivedWeeklyChunks: {
                        ...prev.receivedWeeklyChunks,
                        [completedWeek]: {},
                      },
                    };
                  });
                  break;

                case "getCurrentWeek":
                  setFantasyPointsData((prev) => ({
                    ...prev,
                    currentWeek: data.data.currentWeek,
                  }));
                  break;

                case "getAvailableWeeks":
                  setFantasyPointsData((prev) => ({
                    ...prev,
                    availableWeeks: data.data.weeks,
                  }));
                  break;

                case "getTeamNames":
                  setFantasyPointsData((prev) => ({
                    ...prev,
                    teamNames: data.data,
                  }));
                  break;

                default:
                  logger.warn(
                    `Unhandled fantasy points action: ${data.action}`
                  );
                  break;
              }
            }
          } catch (err) {
            logger.error(`${type}: Error processing message:`, err);
            setError(type, `Error processing message from ${type} connection`);
          }
        };

        // Handle WebSocket connection close
        ws.onclose = (event) => {
          clearTimeouts(type);
          setConnectionStatus((prev) => ({ ...prev, [type]: "CLOSED" }));

          // Implement exponential backoff for reconnection
          connectionAttempts.current[type]++;
          const backoffTime = Math.min(
            1000 * Math.pow(2, connectionAttempts.current[type]),
            30000
          );

          timeoutRefs.current[type] = setTimeout(() => {
            if (isAuthenticated) {
              connectWebSocket(type);
            }
          }, backoffTime);
        };

        // Handle WebSocket errors
        ws.onerror = (error) => {
          setError(type, `${type} connection error`);
        };
      } catch (error) {
        logger.error(`${type}: Setup error:`, error);
        setError(
          type,
          `Failed to establish ${type} connection: ${error.message}`
        );

        // Schedule reconnection attempt
        const backoffTime = Math.min(
          1000 * Math.pow(2, connectionAttempts.current[type]),
          30000
        );
        timeoutRefs.current[type] = setTimeout(() => {
          if (isAuthenticated) {
            connectWebSocket(type);
          }
        }, backoffTime);
      }
    },
    [getAccessTokenSilently, isAuthenticated, clearTimeouts, logger]
  );

  // Initialize WebSocket connections when authenticated
  useEffect(() => {
    if (!isAuthenticated) return;

    connectWebSocket("news");
    connectWebSocket("latestNews");
    connectWebSocket("standings");
    connectWebSocket("fantasyPoints");

    // Cleanup on unmount
    const currentWsRefs = wsRefs.current;
    const currentTimeoutRefs = timeoutRefs.current;

    return () => {
      ["news", "latestNews", "standings", "fantasyPoints"].forEach((type) => {
        if (currentTimeoutRefs[type]) clearTimeout(currentTimeoutRefs[type]);
        if (currentWsRefs[type]) currentWsRefs[type].close();
      });
    };
  }, [connectWebSocket, isAuthenticated, clearTimeouts]);

  // Create context value
  const contextValue = {
    newsData,
    latestNewsData,
    standingsData,
    fantasyPointsData,
    errors,
    connectionStatus,
    sendMessage: useCallback(
      (type, message) => {
        return new Promise((resolve, reject) => {
          const ws = wsRefs.current[type];
          if (!ws || ws.readyState !== WebSocket.OPEN) {
            logger.error(`WebSocket not connected for type: ${type}`);
            reject(new Error(`${type} WebSocket is not connected`));
            return;
          }
          try {
            ws.send(JSON.stringify(message));
            logger.debug(`Sent message to ${type} WebSocket:`, message);
            resolve();
          } catch (err) {
            logger.error(`Failed to send message to ${type} WebSocket:`, err);
            reject(err);
          }
        });
      },
      [logger]
    ),
  };

  // Return the WebSocket Provider with context
  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
});

// Hook for using WebSocket context
export const useWebSocket = () => useContext(WebSocketContext);

export default WebSocketContext;
