import { callExternalApi } from "./external-api.service";
import { logger } from "../utils/logger";
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getAllRosters = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/rosters/allrosters`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await callExternalApi({ config });
    return response.data;
  } catch (error) {
    logger.error('Failed to get all rosters', { error });
    return { rosters: null, error: error.message || 'Unknown error occurred' };
  }
};

export const getRoster = async (accessToken, managerEmail, week) => {
  const encodedEmail = encodeURIComponent(managerEmail);
  const encodedWeek = encodeURIComponent(week);
  const url = `${apiServerUrl}/api/rosters/roster/${encodedEmail}/${encodedWeek}`;
  
  logger.debug('Getting roster', { 
    managerEmail, 
    week,
    url 
  });
  
  const config = {
    url,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await callExternalApi({ config });
    logger.debug('Roster retrieved successfully', { response });
    return response.data;
  } catch (error) {
    logger.error('Failed to get roster', { 
      error,
      managerEmail,
      week 
    });
    return { roster: null, error: error.message || 'Unknown error occurred' };
  }
};

export const getTeamLimits = async (accessToken, managerEmail, week) => {
  const encodedEmail = encodeURIComponent(managerEmail);
  const encodedWeek = encodeURIComponent(week);
  const config = {
    url: `${apiServerUrl}/api/rosters/team-limits/${encodedEmail}?week=${encodedWeek}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await callExternalApi({ config });
    logger.debug('Team limits retrieved successfully', { response });
    return { data: response.data, error: null };
  } catch (error) {
    logger.error("Failed to fetch team limits", { 
      error,
      managerEmail,
      week 
    });
    return { data: null, error: error.message || 'Unknown error occurred' };
  }
};
