import React, {
  useState,
  useEffect,
  useCallback,
  useRef
} from "react";
import { useLogger } from "../utils/logger";
import {
  Table,
  Button,
  Modal,
  Form,
  Alert,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import {
  getAllTrades,
  createTrade,
  updateTrade,
  deleteTrade,
} from "../services/commissioner.service";
import { searchPlayers } from "../services/player.service";
import { useCommissionerCheck } from "../hooks/useCommissionerCheck";
import { useWebSocket } from "../contexts/WebSocketContext";

export const TradeManagement = ({
  selectedTeam,
  selectedTeamName,
  theme,
  fontSize,
  currentWeek,
  selectedWeek,
  weeks,
  remainingTrades,
  onTradeUpdate,
  teamRoster,
  capInfo = {},
  formatCurrency,
  isPlayoffTeam
}) => {
  const logger = useLogger();
  
  // Log component initialization
  logger.debug("Initializing TradeManagement", {
    selectedTeam,
    selectedTeamName,
    currentWeek,
    selectedWeek,
    remainingTrades,
    isPlayoffTeam
  });

  const { getAccessTokenSilently } = useAuth0();
  const { standingsData } = useWebSocket();
  const {
    isCommissioner,
    loading: isCommissionerLoading,
    error: commissionerError,
  } = useCommissionerCheck();

  // State declarations
  const [trades, setTrades] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentTrade, setCurrentTrade] = useState(null);
  const [error, setError] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [formData, setFormData] = useState({
    week: currentWeek,
    tradeType: "",
    playerAdded: "",
    playerDropped: "",
  });
  const [playoffsLoading, setPlayoffsLoading] = useState(true);

  // States for player search
  const [playerAddedOptions, setPlayerAddedOptions] = useState([]);
  const [playerAddedSearch, setPlayerAddedSearch] = useState("");
  const [showPlayerAddedOptions, setShowPlayerAddedOptions] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tradeToDelete, setTradeToDelete] = useState(null);

  const playerAddedRef = useRef(null);
  const headerFontSize = `${parseFloat(fontSize) * 1.5}px`;

  useEffect(() => {
    logger.debug("Cap info updated", { capInfo });
  }, [capInfo, logger]);

  useEffect(() => {
    if (!standingsData) return;
    setPlayoffsLoading(false);
  }, [standingsData]);

  const getAvailableTradeTypes = useCallback(() => {
    const types = [];
    if ((remainingTrades?.regular || 0) > 0) {
      types.push({ value: "regular", label: "Regular" });
    }
    if ((remainingTrades?.ir || 0) > 0) {
      types.push({ value: "ir", label: "IR" });
    }
    logger.debug("Available trade types", { types, remainingTrades });
    return types;
  }, [remainingTrades, logger]);

  const formatTradeType = (type) => {
    return type === "ir" ? "IR" : "Regular";
  };

  const extractWeekNumber = (weekString) => {
    const match = weekString.match(/\d+/);
    return match ? match[0] : "";
  };

  // Trade fetching effect
  const fetchTrades = useCallback(async () => {
    if (!isCommissioner) return;
    try {
      const token = await getAccessTokenSilently();
      const response = await getAllTrades(token);
      const filteredTrades = response.data.filter(
        (trade) => trade.managerEmail === selectedTeam
      );
      logger.debug("Trades fetched successfully", { 
        totalTrades: response.data.length,
        filteredTrades: filteredTrades.length,
        selectedTeam 
      });
      setTrades(filteredTrades);
    } catch (error) {
      logger.error("Failed to fetch trades", { error });
      setError("Failed to fetch trades");
    }
  }, [getAccessTokenSilently, selectedTeam, isCommissioner, logger]);

  useEffect(() => {
    if (selectedTeam && isCommissioner) {
      fetchTrades();
    }
  }, [fetchTrades, selectedTeam, isCommissioner]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        playerAddedRef.current &&
        !playerAddedRef.current.contains(event.target)
      ) {
        setShowPlayerAddedOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    logger.debug("Form input changed", {
      field: name,
      value,
      formData
    });

    if (name === "playerDropped") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        playerAdded: "",
      }));
      setPlayerAddedSearch("");
      setPlayerAddedOptions([]);
      setError(null);
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const weekNum = parseInt(extractWeekNumber(currentWeek));
    if (weekNum > 14 && !isPlayoffTeam) {
      logger.warn("Non-playoff team attempted trade after Week 14", {
        weekNum,
        selectedTeam
      });
      setError("Only playoff teams can make trades after Week 14");
      return;
    }

    const tradeData = {
      managerEmail: selectedTeam,
      week: currentWeek,
      tradeType: formData.tradeType,
      playerAdded: formData.playerAdded,
      playerDropped: formData.playerDropped,
    };

    logger.debug("Submitting trade", {
      isUpdate: !!currentTrade,
      tradeData,
      selectedTeam,
      currentWeek
    });

    try {
      const token = await getAccessTokenSilently();
      
      if (currentTrade) {
        await updateTrade(token, currentTrade.id, tradeData);
      } else {
        await createTrade(token, tradeData);
      }
      setShowModal(false);
      await Promise.all([fetchTrades(), onTradeUpdate?.()]);
    } catch (error) {
      logger.error("Failed to save trade", { 
        error,
        tradeData,
        isUpdate: !!currentTrade 
      });
      setError(
        "Failed to save trade: " +
          (error.response?.data?.error || error.message)
      );
    }
  };

  const handlePlayerSearch = useCallback(
    async (searchTerm) => {
      if (searchTerm.length < 2) {
        setPlayerAddedOptions([]);
        return;
      }

      try {
        setModalLoading(true);
        const token = await getAccessTokenSilently();

        // Calculate available space
        const rosterTotalSalary = teamRoster.reduce((total, player) => {
          if (String(player.playerID) === String(formData.playerDropped)) {
            return total;
          }
          return total + (Number(player.salary) || 0);
        }, 0);

        const availableSpace =
          Number(capInfo.salaryCap || 50) - rosterTotalSalary;

        // Create search parameters
        const searchParams = {
          searchTerm,
          maxSalary: Number(availableSpace),
          currentPlayerId: formData.playerDropped || null,
          week: extractWeekNumber(currentWeek),
          userEmail: selectedTeam,
          forTrade: true 
        };

        logger.debug("Player search parameters", { 
          searchParams,
          availableSpace,
          rosterTotalSalary
        });

        const { data, error } = await searchPlayers(token, searchParams);
        if (error) throw new Error(error);

        // Filter results
        const filteredData = data.filter((player) => {
          const playerSalary = Number(player.salary) || 0;
          return playerSalary <= availableSpace;
        });

        logger.debug("Player search results", {
          totalResults: data.length,
          filteredResults: filteredData.length,
          availableSpace
        });

        if (filteredData.length === 0) {
          setError(
            data.length > 0
              ? `No players found within available cap space (${formatCurrency(
                  availableSpace
                )})`
              : "No matching players found"
          );
          setPlayerAddedOptions([]);
        } else {
          const formattedData = filteredData.map((player) => ({
            ...player,
            displayName: `${player.name} (${player.position} - ${
              player.teamAbv
            }) - ${formatCurrency(player.salary)}`,
          }));

          setPlayerAddedOptions(formattedData);
          setShowPlayerAddedOptions(true);
          setError(null);
        }
      } catch (error) {
        logger.error("Failed to search players", { 
          error,
          searchTerm,
          selectedTeam 
        });
        setError("Failed to search players: " + error.message);
      } finally {
        setModalLoading(false);
      }
    },
    [
      getAccessTokenSilently,
      teamRoster,
      formData.playerDropped,
      capInfo.salaryCap,
      currentWeek,
      formatCurrency,
      selectedTeam,
      logger
    ]
  );

  const handlePlayerSelect = useCallback((player) => {
    logger.debug("Player selected", {
      player: {
        id: player.playerID,
        name: player.displayName
      }
    });
    setFormData((prevData) => ({
      ...prevData,
      playerAdded: player.playerID,
    }));
    setPlayerAddedSearch(player.displayName);
    setShowPlayerAddedOptions(false);
  }, [logger]);

  const openModal = (trade = null) => {
    logger.debug("Opening trade modal", {
      isEdit: !!trade,
      trade,
      weekNum: parseInt(extractWeekNumber(currentWeek))
    });

    const weekNum = parseInt(extractWeekNumber(currentWeek));
    if (weekNum > 14 && !isPlayoffTeam && !trade) {
      setError("Only playoff teams can make trades after Week 14");
      return;
    }

    setCurrentTrade(trade);

    if (trade) {
      setFormData({
        week: currentWeek,
        tradeType: trade.tradeType,
        playerAdded: trade.playerAdded,
        playerDropped: trade.playerDropped,
      });
      setPlayerAddedSearch(trade.playerAddedName || "");
    } else {
      const availableTypes = getAvailableTradeTypes();
      setFormData({
        week: currentWeek,
        tradeType: availableTypes.length > 0 ? availableTypes[0].value : "",
        playerAdded: "",
        playerDropped: "",
      });
      setPlayerAddedSearch("");
    }
    setShowModal(true);
  };

  const handleDelete = async (trade) => {
    logger.debug("Initiating trade deletion", {
      trade,
      weekNum: parseInt(extractWeekNumber(currentWeek))
    });

    const weekNum = parseInt(extractWeekNumber(currentWeek));
    if (weekNum > 14 && !isPlayoffTeam) {
      setError("Only playoff teams can modify trades after Week 14");
      return;
    }

    setTradeToDelete(trade);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      const token = await getAccessTokenSilently();
      await deleteTrade(token, tradeToDelete.id);
      await Promise.all([fetchTrades(), onTradeUpdate?.()]);
      setShowDeleteModal(false);
    } catch (error) {
      logger.error("Failed to delete trade", { 
        error,
        tradeId: tradeToDelete.id 
      });
      setError("Failed to delete trade");
    }
  };

  const handleClose = useCallback(() => {
    logger.debug("Closing trade modal");
    setShowModal(false);
    setPlayerAddedSearch("");
    setPlayerAddedOptions([]);
    setShowPlayerAddedOptions(false);
    setError(null);
  }, [logger]);

  const renderTableRows = () => {
    const weekNum = parseInt(extractWeekNumber(currentWeek));
    const canModify = weekNum <= 14 || isPlayoffTeam;

    logger.debug("Rendering trade table rows", {
      weekNum,
      currentWeek,
      selectedTeam,
      canModify,
      tradesCount: trades.length
    });

    const sortedTrades = [...trades].sort((a, b) => {
      const weekA = parseInt(a.week.match(/\d+/)[0]);
      const weekB = parseInt(b.week.match(/\d+/)[0]);
      return weekB - weekA;
    });

    const hasRemainingTrades =
      (remainingTrades?.regular || 0) > 0 || (remainingTrades?.ir || 0) > 0;

    const canModifyRoster = weekNum <= 14 || isPlayoffTeam;
    return (
      <>
        {sortedTrades.map((trade) => (
          <tr key={trade.id}>
            <td className="trade-week-column">{trade.week}</td>
            <td className="trade-type-column">
              {formatTradeType(trade.tradeType)}
            </td>
            <td className="trade-player-column">{trade.playerAddedName}</td>
            <td className="trade-player-column">{trade.playerDroppedName}</td>
            <td className="trade-actions-column">
              {selectedWeek === currentWeek &&
              trade.week === currentWeek &&
              canModifyRoster ? (
                <>
                  <Button
                    variant="info"
                    size="sm"
                    onClick={() => openModal(trade)}
                    className="me-2"
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(trade)}
                  >
                    Delete
                  </Button>
                </>
              ) : null}
            </td>
          </tr>
        ))}
        {selectedWeek === currentWeek &&
          hasRemainingTrades &&
          canModifyRoster && (
            <tr>
              <td colSpan="5" className="text-center">
                <Button variant="primary" onClick={() => openModal()}>
                  Add Trade
                </Button>
              </td>
            </tr>
          )}
      </>
    );
  };

  if (isCommissionerLoading || playoffsLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (commissionerError || (!isCommissioner && !isCommissionerLoading)) {
    return <Navigate to="/" replace />;
  }

  if (isPlayoffTeam === undefined) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading trade eligibility...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="trade-management-wrapper">
      <div className="trade-management-content">
        <h4 style={{ fontSize: headerFontSize }}>
          Trade Management for {selectedTeamName}
        </h4>
        {error && <Alert variant="danger">{error}</Alert>}
        {parseInt(extractWeekNumber(currentWeek)) > 14 &&
          isPlayoffTeam !== undefined &&
          !isPlayoffTeam && (
            <Alert variant="info">
              Trades are only available for teams that have made the playoffs.
            </Alert>
          )}
        <div className="trade-management-table-container">
          <Table
            bordered
            striped
            hover
            size="sm"
            variant={theme}
            className="align-middle"
          >
            <thead>
              <tr>
                <th className="trade-week-column">Week</th>
                <th className="trade-type-column">Trade Type</th>
                <th className="trade-player-column">Player Added</th>
                <th className="trade-player-column">Player Dropped</th>
                <th className="trade-actions-column">Actions</th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </Table>
        </div>
      </div>

      {/* Trade Edit/Add Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {currentTrade ? "Edit Trade" : "Add New Trade"} for{" "}
            {selectedTeamName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <h6>Available Cap Space:</h6>
            <div className="p-3 bg-light rounded">
              {formatCurrency(
                (capInfo.availableCap || 0) +
                  (formData.playerDropped
                    ? teamRoster.find(
                        (p) =>
                          String(p.playerID) === String(formData.playerDropped)
                      )?.salary || 0
                    : 0)
              )}
            </div>
          </div>

          <Form onSubmit={handleSubmit}>
            <Form.Control
              plaintext
              readOnly
              value={`Week: ${extractWeekNumber(currentWeek)}`}
              className="mb-3"
            />

            {!currentTrade && (
              <Form.Group className="mb-3">
                <Form.Label>Trade Type</Form.Label>
                <Form.Control
                  as="select"
                  name="tradeType"
                  value={formData.tradeType}
                  onChange={handleInputChange}
                >
                  {getAvailableTradeTypes().map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label} ({remainingTrades[type.value]} remaining)
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}

            {currentTrade && (
              <Form.Group className="mb-3">
                <Form.Label>Trade Type</Form.Label>
                <Form.Control
                  plaintext
                  readOnly
                  value={formData.tradeType === "ir" ? "IR" : "Regular"}
                />
              </Form.Group>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Player to Drop</Form.Label>
              <Form.Select
                name="playerDropped"
                value={formData.playerDropped}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a player to drop</option>
                {teamRoster
                  .sort((a, b) => {
                    const positionOrder = {
                      QB1: 0,
                      QB2: 1,
                      RB1: 2,
                      RB2: 3,
                      RB3: 4,
                      WR1: 5,
                      WR2: 6,
                      WR3: 7,
                      TE1: 8,
                      FLEX1: 9,
                      FLEX2: 10,
                    };
                    const orderA = positionOrder[a.position] ?? 999;
                    const orderB = positionOrder[b.position] ?? 999;
                    return orderA - orderB;
                  })
                  .map((player) => (
                    <option key={player.playerID} value={player.playerID}>
                      {player.player_name} ({player.position} - {player.teamAbv}
                      ) - {formatCurrency(player.salary)}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            <Form.Group ref={playerAddedRef}>
              <Form.Label>Player to Add</Form.Label>
              <Form.Control
                type="text"
                value={playerAddedSearch}
                onChange={(e) => {
                  setPlayerAddedSearch(e.target.value);
                  handlePlayerSearch(e.target.value);
                }}
                placeholder="Start typing to search players"
                required
                disabled={modalLoading || !formData.playerDropped}
              />
              {showPlayerAddedOptions && (
                <ListGroup
                  className="position-absolute w-100"
                  style={{ zIndex: 1000 }}
                >
                  {playerAddedOptions.length > 0 ? (
                    playerAddedOptions.map((player, index) => (
                      <ListGroup.Item
                        key={index}
                        action
                        onClick={() => handlePlayerSelect(player)}
                      >
                        {player.displayName}
                      </ListGroup.Item>
                    ))
                  ) : (
                    <ListGroup.Item>No matching players found</ListGroup.Item>
                  )}
                </ListGroup>
              )}
            </Form.Group>

            <Button
              type="submit"
              disabled={
                modalLoading || !formData.playerAdded || !formData.playerDropped
              }
              className="mt-3"
            >
              {modalLoading ? "Saving..." : "Save Trade"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this trade?
          <br />
          <strong>Player Added:</strong> {tradeToDelete?.playerAddedName}
          <br />
          <strong>Player Dropped:</strong> {tradeToDelete?.playerDroppedName}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TradeManagement;
