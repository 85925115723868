import React, { useState } from 'react';
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import Marquee from "react-fast-marquee";
import { NFLNewsFooter } from "./NFLNewsFooter";
import { Container, Row, Col, Form } from 'react-bootstrap';

export const PageFooter = withLDConsumer()(({ flags, fontSize }) => {
  const [isMarqueeVisible, setIsMarqueeVisible] = useState(true);

  const toggleMarqueeVisibility = () => {
    setIsMarqueeVisible(prev => !prev);
  };

  const footerStyle = {
    fontSize: `${fontSize}px`,
    height: '60px',
    overflow: 'hidden'
  };

  const contentStyle = {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  };

  const toggleColumnStyle = {
    width: '150px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px' // Add consistent spacing between label and toggle
  };

  const toggleContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '32px' // Match the height of the news items
  };

  return (
    <footer className="footer page-footer" style={footerStyle}>
      <Container fluid style={contentStyle}>
        <Row className="align-items-center w-100">
          <Col style={{ overflow: 'hidden' }}>
            {flags.footerComponent && isMarqueeVisible ? (
              <Marquee gradientWidth={0}>
                <NFLNewsFooter />
              </Marquee>
            ) : (
              <p className="m-0">
                {flags.footerComponent ? "" : "Footer content is unavailable"}
              </p>
            )}
          </Col>
          <Col xs="auto" style={toggleColumnStyle}>
            <div style={toggleContainerStyle}>
              <Form.Label 
                className="mb-0" 
                style={{ 
                  lineHeight: '32px', // Match height for vertical centering
                  marginRight: '8px'  // Consistent spacing
                }}
              >
                NFL News
              </Form.Label>
              <Form.Check
                type="switch"
                id="nfl-news-toggle"
                checked={isMarqueeVisible}
                onChange={toggleMarqueeVisibility}
                disabled={!flags.footerComponent}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 0    // Remove bottom margin
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
});