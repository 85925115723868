import { useState, useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getTradeCount } from "../services/trades.service";
import { getTeamLimits } from "../services/roster.service";
import { useLogger } from "../utils/logger";

export const useTradeData = (selectedTeam, selectedWeek) => {
  const logger = useLogger();
  const { getAccessTokenSilently } = useAuth0();

  logger.debug("Initializing useTradeData hook", {
    selectedTeam,
    selectedWeek
  });
  const [regularTradeCount, setRegularTradeCount] = useState(0);
  const [regularTradeLimit, setRegularTradeLimit] = useState(0);
  const [irTradeCount, setIRTradeCount] = useState(0);
  const [irTradeLimit, setIRTradeLimit] = useState(0);
  const [tradeError, setTradeError] = useState(null);

  const fetchTradeCounts = useCallback(async () => {
    if (!selectedTeam || !selectedWeek) {
      logger.debug("Missing required data for trade counts", {
        selectedTeam,
        selectedWeek
      });
      return;
    }

    try {
      logger.debug("Fetching trade counts", {
        selectedTeam,
        selectedWeek
      });
      const accessToken = await getAccessTokenSilently();
      
      const [regularCountResponse, irCountResponse, teamLimitsResponse] = await Promise.all([
        getTradeCount(accessToken, selectedTeam, "Week 2", selectedWeek, "regular"),
        getTradeCount(accessToken, selectedTeam, "Week 2", selectedWeek, "ir"),
        getTeamLimits(accessToken, selectedTeam, selectedWeek)  // Pass selectedWeek here
      ]);

      logger.debug("Trade count responses received", {
        regularCount: regularCountResponse.data,
        irCount: irCountResponse.data,
        teamLimits: teamLimitsResponse.data
      });

      if (regularCountResponse.error || irCountResponse.error || teamLimitsResponse.error) {
        const error = "There was an issue fetching trade data. Some data may be unavailable.";
        logger.warn("Trade data fetch error", {
          regularError: regularCountResponse.error,
          irError: irCountResponse.error,
          limitsError: teamLimitsResponse.error
        });
        setTradeError(error);
        return;
      }

      // Update trade counts and limits
      const newRegularCount = regularCountResponse.data;
      const newIRCount = irCountResponse.data;
      const newRegularLimit = teamLimitsResponse.data.tradeLimits.regular;
      const newIRLimit = teamLimitsResponse.data.tradeLimits.ir;

      logger.debug("Updating trade counts and limits", {
        regularCount: newRegularCount,
        irCount: newIRCount,
        regularLimit: newRegularLimit,
        irLimit: newIRLimit
      });

      setRegularTradeCount(newRegularCount);
      setIRTradeCount(newIRCount);
      setRegularTradeLimit(newRegularLimit);
      setIRTradeLimit(newIRLimit);
      setTradeError(null);

      logger.debug("Trade data update complete", {
        selectedTeam,
        selectedWeek
      });
    } catch (error) {
      logger.error("Failed to fetch trade data", { error });
      setTradeError("Failed to load trade information");
    }
  }, [selectedTeam, selectedWeek, getAccessTokenSilently, logger]);

  return {
    regularTradeCount,
    regularTradeLimit,
    irTradeCount,
    irTradeLimit,
    tradeError,
    fetchTradeCounts
  };
};
